import React, { useState } from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { checkByRegnum } from "../services/requests";
import { Button, ConfigProvider, Input, message, Table } from "antd";
import { FaSearch } from "react-icons/fa";
import moment from "moment";

export default function Regnumsearch() {
  const [dataSource, setDataSource] = useState([]);
  const [register, setRegister] = useState("");
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Овог",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Өөрийн нэр",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Регистрийн дугаар",
      dataIndex: "register",
      key: "register",
    },
    {
      title: "Ажилладаг байгууллага",
      key: "subOrganization",
      dataIndex: "subOrganization",
      render: (_, record) => record.subOrganization?.name || record.orgName,
    },
    {
      title: "Шагнагдсан огноо",
      key: "tushaalDate",
      dataIndex: "tushaalDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Шагнал",
      key: "awardTable",
      dataIndex: "awardTable",
      render: (_, record) => record.awardTable?.name,
    },
  ];

  const handleSearch = () => {
    checkByRegnum({ register })
      .then((response) => {
        if (response.result) {
          console.log(response);
          setDataSource(response.data);
        } else {
          message.error(response.error.response.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Layout>
      <Header title="Регистрийн дугаараар хайх" />
      <div className="p-6">
        <div className="flex gap-4 items-center mb-10">
          <Input
            placeholder="Регистрийн дугаараар хайх"
            value={register}
            onChange={(e) => setRegister(e.target.value)}
            style={{ width: 300 }}
          />

          <Button type="primary" onClick={handleSearch}>
            <FaSearch color="white" size={16} />
          </Button>
        </div>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Golos text",
              colorText: "#334155",
              fontWeightStrong: 600,
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={dataSource && dataSource}
            scroll={{ x: "100%" }}
            // pagination={{
            //   current: currentPage,
            //   pageSize: pageSize,
            //   onChange: handlePageChange,
            //   showSizeChanger: true,
            //   position: ["bottomRight"],
            //   locale: { items_per_page: "" },
            //   pageSizeOptions: ["5", "10", "20", "50"],
            //   onShowSizeChange: (current, size) => setPageSize(size),
            //   showTotal: showTotal,
            // }}
          />
        </ConfigProvider>
      </div>
    </Layout>
  );
}
