import { useContext, useState } from "react";
import hammer from "../assets/sidebar/Vector.svg";
import hammeractive from "../assets/sidebar/hammeractive.svg";
import clipboard from "../assets/sidebar/clipboard.svg";
import clipboardActive from "../assets/sidebar/clipboardwhite.svg";
import logo from "../assets/sidebar/logo.svg";
import logosmall from "../assets/sidebar/logosmall.svg";
import { Link, useLocation } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import usergroup from "../assets/sidebar/user-group.svg";
import usergroupactive from "../assets/sidebar/user-groupactive.svg";
import awards from "../assets/sidebar/award-02.svg";
import awardactive from "../assets/sidebar/awardwhite.svg";
import { FaRegUser } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";
import { BsCalendar2DateFill } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { AiOutlineLogout } from "react-icons/ai";
import SidebarContext from "../context/SidebarContext";
import add from "../assets/sidebar/add-circle.svg";
import addactive from "../assets/sidebar/addwhite.svg";
import { useAuth } from "../context/AuthContext";
import { TbReport } from "react-icons/tb";
import { Modal } from "antd";
import juram from "../assets/PDF/juram.pdf";

export default function Sidebar({}) {
  const [active, setActive] = useState(false);
  const { isCollapsed, toggleSidebar } = useContext(SidebarContext);
  const { user, logout } = useAuth();
  const routes = [
    {
      name: "Хүсэлтийн жагсаалт",
      icon: add,
      subPaths: ["/requests/create"],
      path: "/requests",
      active: addactive,
      roles: ["admin", "user", "headman"],
    },
    {
      name: "Архив",
      icon: clipboard,
      path: "/archive",
      subPaths: ["/archive/create"],
      active: clipboardActive,
      roles: ["admin", "headman"],
    },
    {
      name: "Хянасан хүсэлтийн жагсаалт",
      icon: add,
      // subPaths: ["/requests/create"],
      path: "/reviewed",
      active: addactive,
      roles: ["admin", "chiefman"],
    },

    {
      name: "Байгууллагын жагсаалт",
      icon: usergroup,
      path: "/organizations",
      subPath: ["/organizations/create"],
      active: usergroupactive,
      roles: ["admin", "headman"],
    },
    {
      name: "Регистрээр хайх",
      icon: add,
      path: "/regnum/search",
      // subPath: ["/organizations/create"],
      active: addactive,
      roles: ["admin", "user", "chiefman", "headman"],
    },
    {
      name: "Салбар байгууллагуудын жагсаалт",
      icon: usergroup,
      subPath: [""],
      path: "/suborgs",
      active: usergroupactive,
      roles: ["admin", "headman"],
    },
    {
      name: "Хэрэглэгчийн жагсаалт",
      icon: usergroup,
      path: "/user/list",
      subPath: [""],
      active: usergroupactive,
      roles: ["admin", "headman"],
    },
    {
      name: "Баяр тэмдэглэлт өдрүүдийн жагсаалт",
      icon: <BsCalendar2DateFill color="#2C73EB" width={20} height={20} />,
      reactIcon: true,
      subPath: [""],
      path: "/holidays/list",
      active: <BsCalendar2DateFill color="#FFF" width={20} height={20} />,
      roles: ["admin", "headman"],
    },
    {
      name: "Тушаалын жагсаалт",
      icon: hammer,
      subPath: [""],
      path: "/orders/list",
      active: hammeractive,
      roles: ["admin", "headman"],
    },
    {
      name: "Шагналын төрлүүд",
      icon: awards,
      subPath: [""],
      path: "/awards",
      active: awardactive,
      roles: ["admin", "headman"],
    },
    {
      name: "Тайлан",
      reactIcon: true,
      icon: <TbReport color="#2C73EB" width={20} height={20} />,
      subPath: [""],
      path: "/report",
      active: <TbReport color="#FFF" width={20} height={20} />,
      roles: ["admin", "headman"],
    },

    {
      name: "",
      icon: hammer,
      subPath: ["/awards/edit/:id"],
      path: "/",
      roles: ["admin", "user", "chiefman", "headman"],
    },
  ];
  const location = useLocation();
  const pathname = location.pathname;
  const onLogout = async () => {
    await logout();
  };
  const [show, setShow] = useState();
  const toggleModal = () => {
    setShow(!show);
  };
  return (
    <div
      className={`${
        isCollapsed ? "w-[96px]" : "w-[330px]"
      } transition-width duration-300 bg-gray-50 relative flex flex-col justify-between h-screen px-5 pt-8 pb-10 border-r border-r-slate-200`}
    >
      <div
        onClick={toggleSidebar}
        className="absolute w-2 top-0 bottom-0 right-0 z-[100] positioning cursor-pointer group"
      >
        <div className=" absolute line  opacity-0 transform -translate-x-1/2 transition-opacity duration-300 ease-[cubic-bezier(0.25,0.01,0.25,1)] group-hover:opacity-100 left-1/2 top-0 bottom-0 line w-[2px] h-full bg-blue-700"></div>
        <div className=" absolute z-[150] top-12 rounded-[50%] left-1/2 line opacity-0 translate-50 transform -translate-x-1/2 transition-opacity-transform duration-200 ease-custom-ease group-hover:opacity-100 flex items-center justify-center w-6 h-6 bg-blue-700">
          {isCollapsed ? (
            <FaAngleRight color="white" height={20} width={20} />
          ) : (
            <FaAngleLeft color="white" height={20} width={20} />
          )}
        </div>
      </div>

      <div className="flex flex-col gap-8">
        <div className="flex justify-between items-center">
          {isCollapsed ? (
            <img
              src={logosmall}
              alt="logo"
              width={56}
              height={56}
              className=" object-cover bg-cover"
            />
          ) : (
            <img src={logo} alt="logo" width={254} height={56} />
          )}
        </div>
        <div className="flex flex-col gap-4">
          {routes.slice(0, 11).map((route, index) => (
            <>
              {route.roles.includes(user?.refRole?.name) && (
                <Link
                  to={route.path}
                  className={`flex rounded-[8px] p-3 gap-2 transition-all ease-in duration-300 items-center ${
                    pathname === route.path || route.subPath === pathname
                      ? "bg-[#2c73eb] text-white"
                      : "bg-slate-100 text-slate-700"
                  } ${isCollapsed ? "justify-center" : ""}`}
                  key={index}
                >
                  {pathname === route.path || pathname === route.subPath ? (
                    <>
                      {route.reactIcon ? (
                        route.active
                      ) : (
                        <img
                          src={route.active}
                          alt="icon"
                          width={20}
                          height={20}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {route.reactIcon ? (
                        route.icon
                      ) : (
                        <img
                          src={route.icon}
                          alt="icon"
                          width={20}
                          height={20}
                        />
                      )}
                    </>
                  )}
                  {!isCollapsed && (
                    <span className="text-sm font-medium">{route.name}</span>
                  )}
                </Link>
              )}
            </>
          ))}
        </div>
      </div>
      <div className="flex flex-col justify-end gap-2 mb-4">
        <div
          onClick={toggleModal}
          className={`flex rounded-[8px] p-3 gap-2 cursor-pointer ${
            isCollapsed ? "justify-center" : ""
          }`}
        >
          {" "}
          <img src={hammer} alt="icon" width={20} height={20} />
          {!isCollapsed && (
            <span className="text-sm font-medium">Шагнал олгох журам</span>
          )}
        </div>
        {/* {routes.slice(10, 11).map((route, index) => (
          <Link
            to={route.path}
            className={`flex rounded-[8px] p-3 gap-2 ${
              isCollapsed ? "justify-center" : ""
            }`}
            key={index}
          >
            <img src={route.icon} alt="icon" width={20} height={20} />
            {!isCollapsed && (
              <span className="text-sm font-medium">{route.name}</span>
            )}
          </Link>
        ))} */}
        <div
          // onMouseEnter={() => setActive(!active)}
          onClick={() => setActive(!active)}
          className={`flex ${
            isCollapsed ? "justify-center" : "justify-between gap-2"
          } rounded-[8px] p-3 relative bg-slate-100 cursor-pointer`}
        >
          <div className="flex items-center gap-2">
            <span className="rounded-full bg-white p-2 flex justify-center">
              <FaRegUser size={15} />
            </span>
            {!isCollapsed && <span>{user.firstName || ""}</span>}
            {!active ? (
              <IoIosArrowDown size={15} className="absolute right-5" />
            ) : (
              <IoIosArrowUp size={15} className="absolute right-5" />
            )}
          </div>
          {active && (
            <div>
              {active && (
                <div className="absolute -top-[115px] left-1/2 transform -translate-x-1/2 pt-4">
                  <div className="bg-white rounded-2xl overflow-hidden border border-black/[0.1] shadow-md">
                    <div className="w-max flex flex-col gap-4 h-full p-4">
                      <Link
                        to="/profile"
                        className="flex items-center gap-2 justify-start"
                      >
                        <FaCircleUser size={18} color="#2c73eb" />
                        Хэрэглэгчийн мэдээлэл
                      </Link>
                      <button
                        onClick={onLogout}
                        className="flex items-center gap-2 justify-start"
                      >
                        {/* <img src={logout1} alt="icon" width={20} height={20} /> */}
                        <AiOutlineLogout size={20} color="red" />
                        <div>Гарах</div>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Modal
        open={show}
        centered
        onOk={toggleModal}
        onCancel={toggleModal}
        width={"100%"}
        cancelText="Хаах"
        footer={null}
      >
        <iframe
          src={juram}
          frameborder="0"
          id="pdf"
          width="100%"
          height="800px"
          title=" "
        ></iframe>
      </Modal>
    </div>
  );
}
