import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const AnketPdf = React.forwardRef((data, ref) => {
  return (
    <div ref={ref}>
      <div className="mx-10">
        <div id="divToPrint" className="mt-4 ml-10 text-start">
          <div className="ml-5">
            <h1 className="font-bold text-xl">Танилцуулга</h1>
            <h3>Ерөнхий мэдээлэл</h3>
          </div>
          <table className="pdfTable">
            <tr className="anket">
              <td className="head">Баяр тэмдэглэлт өдөр:</td>
              <td className="value">{data.data?.infoData?.refHoliday?.name}</td>
              <td className="head">Харьяалагдах яам:</td>
              <td className="value">{data.data?.infoData?.awardOrg?.name}</td>
            </tr>
            <tr className="anket">
              <td className="head">Шагналын нэр:</td>
              <td className="value">
                {" "}
                {data.data?.infoData?.awardTable?.name}
              </td>
              <td className="head">Шагналын төрөл:</td>
              <td className="value">
                {data.data?.infoData?.refAwardCategory?.name}
              </td>
            </tr>
          </table>
          <table className="pdfTable">
            <tr className="anket">
              <td rowspan="6" className="w-[100px] h-[100px]">
                <img src={data?.src} alt="" />
              </td>
            </tr>
            <tr className="anket">
              <td className="head">Овог нэр:</td>
              <td className="value">{data.data?.infoData?.lastName}</td>
              <td className="head">Өөрийн нэр:</td>
              <td className="value">{data.data?.infoData?.firstName}</td>
            </tr>
            <tr className="anket">
              <td className="head">Төрсөн огноо:</td>
              <td>{data.data?.infoData?.birthDate}</td>
              <td className="head">Регистрийн дугаар:</td>
              <td className="value">{data.data?.infoData?.register}</td>
            </tr>
            <tr className="anket">
              <td className="head">Хүйс:</td>
              <td className="value">{data.data?.infoData?.refGender?.name}</td>
              <td className="head">Боловсрол:</td>
              <td className="value">
                {data.data?.infoData?.refEducationType?.name}
              </td>
            </tr>
            <tr className="anket">
              <td className="head">Мэргэжил:</td>
              <td className="value">
                {data.data?.infoData?.refOccupation?.name}
              </td>
              <td className="head">Ажилладаг салбар:</td>
              <td className="value">
                {data.data?.infoData?.refDivision?.name}
              </td>
            </tr>
            <tr className="anket">
              <td className="head">Нийт ажилласан жил:</td>
              <td className="value">{data.data?.infoData?.workYear}</td>
              <td className="head">Салбарт ажилласан жил:</td>
              <td className="value">{data.data?.infoData?.workDevisionYear}</td>
            </tr>
          </table>

          <h3 className="ml-5 mt-5">Шагналын мэдээлэл</h3>
          <table className="pdfTable">
            <tr className="anket">
              <th className="head">№</th>
              <th className="head">Шагналын нэр</th>
              <th className="head">Өгсөн огноо</th>
            </tr>
            <tbody>
              {data.data?.awardHistory.map((item, index) => (
                <tr key={index} className="anket">
                  <td className="value">{index + 1}</td>
                  <td className="value">{item?.awardName}</td>
                  <td className="value">{item?.giveDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <h3 className="ml-5 mt-5">Ажлын туршлага</h3>
          <table className="pdfTable">
            <tr className="anket">
              <th className="head">№</th>
              <th className="head">Ажлын газар</th>
              <th className="head">Албан тушаал</th>
              <th className="head">Орсон огноо</th>
              <th className="head">Гарсан огноо</th>
              <th className="head">Ажиллаж байгаа эсэх</th>
            </tr>
            <tbody>
              {data.data?.workplace.map((item, index) => (
                <tr key={index} className="anket">
                  <td className="value">{index + 1}</td>
                  <td className="value">{item?.workName}</td>
                  <td className="value">{item?.workPosition}</td>
                  <td className="value">{item?.startDate}</td>
                  <td className="value">{item?.endDate}</td>
                  <td className="value">{item?.isCurrent ? "Тийм" : "Үгүй"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export default AnketPdf;
