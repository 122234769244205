import {
  Description,
  Field,
  Fieldset,
  Input,
  Label,
  Legend,
  Select,
  Textarea,
  Button,
} from "@headlessui/react";
import { cn } from "../lib/utils";
import { FaChevronDown } from "react-icons/fa";
import {
  getAwardOrgs,
  getDivisions,
  getOccupations,
  getGenders,
} from "../services/index";
import { getAwards } from "../services/awards";
import { registerArchive } from "../services/archives";
import { useEffect, useState } from "react";
import { message } from "antd";

export const RegisterArchiveForm = ({ next }) => {
  const [awards, setAwards] = useState();
  const [awardOrgs, setAwardOrgs] = useState();
  const [divisions, setDivisions] = useState([]);
  const [divisionId, setDivisionId] = useState(1);
  const [occupations, setOccupations] = useState([]);
  const [occupationId, setOccupationId] = useState(1);
  const [loading, setLoading] = useState(true);
  const [awardId, setAwardId] = useState(0);
  const [awardOrgId, setAwardOrgId] = useState(1);
  const [gender, setGender] = useState([]);
  const [genderId, setGenderId] = useState(1);
  const [formValues, setFormValues] = useState({
    org: awardOrgId,
    award: awardId,
    register: "",
    firstName: "",
    lastName: "",
    licenseNum: "",
    orgName: "",
    orderDate: "",
    orderNum: "",
    phoneNumber: "",
    email: "",
    position: "",
    description: "",
    age: "",
    gender: genderId,
    divisionId: divisionId,
    occupationId: occupationId,
    workYear: "",
    workDevisionYear: "",
  });

  function fetchAwards() {
    getAwards()
      .then((response) => {
        setAwards(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchDivision() {
    getDivisions()
      .then((response) => {
        setDivisions(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchOccupations() {
    getOccupations()
      .then((response) => {
        setOccupations(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchGenders() {
    getGenders()
      .then((response) => {
        if (response) {
          setGender(response);
          setLoading(false);
          console.log("gender", gender);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchOrgs() {
    getAwardOrgs()
      .then((response) => {
        setAwardOrgs(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  useEffect(() => {
    fetchOrgs();
    fetchAwards();
    fetchDivision();
    fetchOccupations();
    fetchGenders();
  }, []);

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    console.log(`setting ${name} to ${value}`);
    if (name === "award") {
      setAwardId(value);
    } else if (name === "org") {
      setAwardOrgId(value);
    } else if (name === "gender") {
      setGenderId(value);
    } else if (name === "division") {
      setDivisionId(value);
    } else if (name === "occupation") {
      setOccupationId(value);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  function createArchive() {
    return registerArchive(formValues)
      .then((response) => {
        if (response.statusCode === 201) {
          return true;
        } else if (response.statusCode === 403) {
          message.error(response.message);
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }
  async function onSubmit(e) {
    e.preventDefault();

    const archiveCreated = await createArchive();
    if (archiveCreated) {
      next();
    }
  }

  return (
    <>
      <form onSubmit={(e) => onSubmit(e)}>
        <Fieldset className="gap-6 rounded-xl bg-white/5 ">
          <div className="flex flex-col gap-4 pb-5 ">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <span className=" text-[#2C73EB] font-medium text-base">
                  ШАГНАЛ СОНГОХ:
                </span>
                <div className="border-b-2 border-[#2C73EB]"></div>
              </div>

              <div className="w-full grid grid-cols-4 gap-6">
                <Field>
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Харьяалагдах яам
                  </Label>
                  <div className="relative">
                    <Select
                      name="org"
                      onChange={handleSelectChange}
                      className={cn(
                        "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                        " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                    >
                      <option value="" disabled selected hidden>
                        Харьяалагдах яам сонгоно уу
                      </option>
                      {awardOrgs &&
                        awardOrgs.map((awardOrg) => (
                          <option value={awardOrg.id} key={awardOrg.id}>
                            {awardOrg.name}
                          </option>
                        ))}
                    </Select>
                    <FaChevronDown
                      color="#475569"
                      className=" group pointer-events-none absolute top-4 right-3 size-3 "
                    />
                  </div>
                </Field>
                <Field>
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Шагналын нэр
                  </Label>
                  <div className="relative">
                    <select
                      className={cn(
                        "mt-3 text-[#475569] block w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                        " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                      value={awardId}
                      name="award"
                      onChange={handleSelectChange}
                      // defaultValue={1}
                    >
                      <option value="" disabled selected hidden>
                        Шагнал сонгоно уу
                      </option>
                      {awards &&
                        awards.map((award) => (
                          <option value={award.id} key={award.id}>
                            {award.name}
                          </option>
                        ))}
                    </select>
                    <FaChevronDown
                      color="#475569"
                      className=" group pointer-events-none absolute top-4 right-3 size-3 "
                    />
                  </div>
                </Field>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <span className=" text-[#2C73EB] font-medium text-base ">
                ХУВИЙН МЭДЭЭЛЭЛ:
              </span>
              <div className="border-b-2 border-[#2C73EB]"></div>
            </div>
            <div className="w-full  grid grid-cols-4 gap-6">
              <Field>
                <Label className="text-sm/6 font-medium text-slate-700">
                  Эцэг эхийн нэр
                </Label>

                <Input
                  value={formValues.lastName}
                  name="lastName"
                  placeholder="Утга бичнэ үү"
                  onChange={handleChange}
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>
              <Field>
                <Label className="text-sm/6 font-medium text-slate-700">
                  Өөрийн нэр
                </Label>

                <Input
                  value={formValues.firstName}
                  name="firstName"
                  placeholder="Утга бичнэ үү"
                  onChange={handleChange}
                  required={true}
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>
              <Field>
                <Label className="text-sm/6 font-medium text-slate-700">
                  Регистер
                </Label>

                <Input
                  onChange={handleChange}
                  value={formValues.register}
                  placeholder="Утга бичнэ үү"
                  name="register"
                  required={true}
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>
              <Field>
                <Label className="text-sm/6 font-medium text-slate-700">
                  Нас
                </Label>
                <Input
                  value={formValues.age}
                  placeholder="Утга бичнэ үү"
                  type="number"
                  onChange={handleChange}
                  name="age"
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>
              <Field className="relative">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Хүйс
                </Label>
                <Select
                  // value={gender}
                  name="gender"
                  onChange={handleSelectChange}
                  className={cn(
                    "mt-3 block text-[#475569] w-full relative appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                    " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                >
                  <option value="" disabled selected hidden>
                    Хүйс сонгоно уу
                  </option>
                  {gender &&
                    gender.data &&
                    gender.data.map((choice) => (
                      <option value={choice.value} key={choice.name}>
                        {choice.name}
                      </option>
                    ))}
                </Select>
                <FaChevronDown
                  color="#475569"
                  className=" group pointer-events-none absolute top-[52px] right-3 size-3 "
                />
              </Field>
              <Field className="relative">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Ажилладаг салбар
                </Label>
                <Select
                  // value={divisionId}
                  name="division"
                  onChange={handleSelectChange}
                  // defaultValue={1}
                  className={cn(
                    "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                    " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                >
                  <option value="" disabled selected hidden>
                    Ажилладаг салбар сонгоно уу
                  </option>
                  {divisions &&
                    divisions.data &&
                    divisions.data.map((choice) => (
                      <option value={choice.id} key={choice.name}>
                        {choice.name}
                      </option>
                    ))}
                </Select>
                <FaChevronDown
                  color="#475569"
                  className=" group pointer-events-none absolute top-[52px] right-3 size-3 "
                />
              </Field>
              <Field className="relative">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Мэргэжил
                </Label>
                <Select
                  // value={occupationId}
                  name="occupations"
                  onChange={handleSelectChange}
                  // defaultValue={1}
                  className={cn(
                    "mt-3 block text-[#475569] w-full relative appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                    " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                >
                  {" "}
                  <option value="" disabled selected hidden>
                    Мэргэжил сонгоно уу
                  </option>
                  {occupations &&
                    occupations.data &&
                    occupations.data.map((choice) => (
                      <option value={choice.id} key={choice.name}>
                        {choice.name}
                      </option>
                    ))}
                </Select>
                <FaChevronDown
                  color="#475569"
                  className=" group pointer-events-none absolute top-[52px] right-3 size-3 "
                />
              </Field>
              <Field>
                <Label className="text-sm/6 font-medium text-slate-700">
                  Нийт ажилласан жил
                </Label>
                <Input
                  value={formValues.workYear}
                  placeholder="Утга бичнэ үү"
                  type="number"
                  onChange={handleChange}
                  name="workYear"
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>
              <Field>
                <Label className="text-sm/6 font-medium text-slate-700">
                  Тус салбарт ажилласан жил
                </Label>
                <Input
                  value={formValues.workDevisionYear}
                  placeholder="Утга бичнэ үү"
                  type="number"
                  onChange={handleChange}
                  name="workDevisionYear"
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>
              <Field>
                <Label className="text-sm/6 font-medium text-slate-700">
                  Үнэмлэхний дугаар
                </Label>

                <Input
                  placeholder="Утга бичнэ үү"
                  value={formValues.licenseNum}
                  onChange={handleChange}
                  name="licenseNum"
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>
              <Field>
                <Label className="text-sm/6 font-medium text-slate-700">
                  Ажилладаг байгууллагын нэр
                </Label>

                <Input
                  placeholder="Утга бичнэ үү"
                  value={formValues.orgName}
                  onChange={handleChange}
                  name="orgName"
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>
              <Field>
                <Label className="text-sm/6 font-medium text-slate-700">
                  Албан тушаал
                </Label>
                <Input
                  value={formValues.position}
                  placeholder="Утга бичнэ үү"
                  onChange={handleChange}
                  name="position"
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>

              <Field>
                <Label className="text-sm/6 font-medium text-slate-700">
                  Тушаалын огноо
                </Label>

                <Input
                  value={formValues.orderDate}
                  onChange={handleChange}
                  placeholder="YYYY/MM/DD"
                  name="orderDate"
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>
              <Field>
                <Label className="text-sm/6 font-medium text-slate-700">
                  Тушаалын дугаар
                </Label>

                <Input
                  value={formValues.orderNum}
                  onChange={handleChange}
                  name="orderNum"
                  placeholder="Утга бичнэ үү"
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>

              <Field>
                <Label className="text-sm/6 font-medium text-slate-700">
                  Холбоо барих утас
                </Label>

                <Input
                  value={formValues.phoneNumber}
                  onChange={handleChange}
                  name="phoneNumber"
                  placeholder="Утга бичнэ үү"
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>
              <Field>
                <Label className="text-sm/6 font-medium text-slate-700">
                  Цахим шуудангийн хаяг
                </Label>

                <Input
                  value={formValues.email}
                  onChange={handleChange}
                  placeholder="Утга бичнэ үү"
                  name="email"
                  className={cn(
                    "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                />
              </Field>
            </div>
            <Field className="">
              <Label className="text-sm/6 font-medium text-slate-700">
                Нэмэлт тайлбар
              </Label>

              <Textarea
                value={formValues.description}
                placeholder="Утга бичнэ үү"
                onChange={handleChange}
                name="description"
                className={cn(
                  "mt-3 block w-full resize-none rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                  "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                )}
                rows={3}
              />
            </Field>
          </div>

          <Button
            type="submit"
            className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
          >
            Хадгалах
          </Button>
        </Fieldset>
      </form>
    </>
  );
};
