import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import plus from "../assets/plus-sign.svg";
import upload from "../assets/upload-04.svg";
import {
  ConfigProvider,
  Drawer,
  Input,
  Table,
  message,
  Upload,
  DatePicker,
} from "antd";
import { Field, Label } from "@headlessui/react";
import { addOrder, deleteOrder, OrdersList, UploadFile } from "../services";
import moment from "moment";
import { MdDelete, MdOutlineEdit } from "react-icons/md";
import { PiDotsThreeCircleFill } from "react-icons/pi";
import { useAuth } from "../context/AuthContext";

export default function Orders() {
  const { token } = useAuth();

  const [orders, setOrders] = useState();
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showMore, setShowMore] = useState(false);

  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    issue: "",
    file: null,
    file1: null,
    date: null,
  });
  function fetchOrders() {
    OrdersList()
      .then((response) => {
        console.log(response.data);
        setOrders(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  const props = {
    action: UploadFile(),
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onChange(info, fieldName) {
      //   const { status } = info.file;

      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        if (info.file.response && info.file.response.result === false) {
          message.error(info.file.response.message);
        } else {
          setFormData((prevData) => ({
            ...prevData,
            [fieldName]: info.file.response,
          }));
          //   message.success("File uploaded successfully!");
        }
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleUploadChange = (info, fieldName) => {
    const { status } = info.file;

    if (status === "done") {
      if (info.file.response && info.file.response.result === false) {
        message.error(info.file.response.message);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [fieldName]: info.file.response,
        }));
        message.success("Файлыг амжилттай хууллаа");
      }
    } else if (status === "error") {
      message.error("Файлыг хуулахад алдаа гарлаа");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const toggleMenu = () => {
    setShowMore(!showMore);
  };
  async function removeOrder(id) {
    try {
      const response = await deleteOrder(id);
      if (response) {
        setOrders((prevData) => prevData.filter((item) => item.id !== id));
        console.log("Successfully deleted");
        message.success("Амжилттай устгагдлаа");
      } else {
        message.error("Устгахад алдаа гарлаа");
      }
    } catch (error) {
      message.error("Алдаа гарлаа. Та дахин оролдоно уу.");
    }
  }
  const handleDateChange = (fieldName, dateString) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: dateString,
    }));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Тушаал",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Тушаалын дугаар",
      dataIndex: "issue",
      key: "issue",
    },
    {
      title: "Тушаалын огноо",
      dataIndex: "date",
      key: "date",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Үйлдэл",
      key: "action",
      dataIndex: "action",
      render: (_, record) => (
        <div style={{ position: "relative", display: "inline-block" }}>
          {!showMore && (
            <button
              onClick={toggleMenu}
              className="font-bold transition-all duration-300 ease-in"
            >
              <PiDotsThreeCircleFill size={25} color="black" fill="#ccc" />
            </button>
          )}

          {showMore && (
            <div className="flex gap-2 items-center justify-between">
              <button onClick={() => removeOrder(record?.id)}>
                <MdDelete size={25} fill="" color="red" />
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];
  const showTotal = (total) => (
    <div className="font-semibold">Нийт - {total}</div>
  );

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    addOrder(formData)
      .then((response) => {
        message.success(response.message);
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
      });
  };
  return (
    <Layout>
      <Header title="Тушаалын жагсаалт" />
      <div className="p-6">
        <span className="flex justify-end items-center mb-3">
          <button
            className="pl-4 flex items-center gap-[10px] pr-5 py-2 text-white bg-[#2C73EB] rounded-xl  text-center"
            onClick={showDrawer}
          >
            <img src={plus} alt="" />
            <span className="text-sm font-bold">Шинэ бүртгэл</span>
          </button>
        </span>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Golos text",
              colorText: "#334155",
              fontWeightStrong: 600,
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={orders}
            scroll={{ x: "100%" }}
            rowKey="id"
            pagination={{
              showTotal: showTotal,
              current: currentPage,
              pageSize: pageSize,
              onChange: handlePageChange,
              showSizeChanger: true,
              position: ["bottomLeft"],
              locale: { items_per_page: "" },
              pageSizeOptions: ["5", "10", "20", "50"],
              onShowSizeChange: (current, size) => setPageSize(size),
            }}
          />
        </ConfigProvider>
        <Drawer open={open} onClose={onClose} width={720}>
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="grid grid-cols-2">
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Тушаалын тэргүү
                </Label>
                <Input
                  name="name"
                  placeholder="Утга бичнэ үү"
                  className="inputStyle"
                  onChange={handleChange}
                />
              </Field>
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Тушаалын дугаар
                </Label>
                <Input
                  name="issue"
                  placeholder="Утга бичнэ үү"
                  className="inputStyle"
                  onChange={handleChange}
                />
              </Field>
            </div>
            <div className="grid grid-cols-2">
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Тушаалын нүүр
                </Label>
                <Upload
                  onChange={(info) => handleUploadChange(info, "file")}
                  action={UploadFile()}
                  headers={{
                    Authorization: `Bearer ${token}`,
                  }}
                  // name="file"
                  className="inputStyle"
                >
                  <div className="flex gap-[10px] items-center cursor-pointer">
                    <img src={upload} alt="upload" className="w-5 h-5" />
                    <span>Файл хуулах</span>
                  </div>
                </Upload>
              </Field>
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Тушаал хавсралт
                </Label>
                <Upload
                  onChange={(info) => handleUploadChange(info, "file1")}
                  action={UploadFile()}
                  headers={{
                    Authorization: `Bearer ${token}`,
                  }}
                  className="inputStyle"
                  // name="file1"
                >
                  <div className="flex gap-[10px] items-center cursor-pointer">
                    <img src={upload} alt="upload" className="w-5 h-5" />
                    <span>Файл хавсралт</span>
                  </div>
                </Upload>
              </Field>
              <Field className="w-10/12 flex flex-col">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Тушаалын огноо
                </Label>

                <DatePicker
                  className="inputStyle"
                  placeholder="Огноо сонгоно уу"
                  onChange={(dateString, index) =>
                    handleDateChange("date", dateString)
                  }
                />
              </Field>
            </div>
            <div className="flex justify-end mr-14">
              <button
                type="submit"
                className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                Бүртгэх
              </button>
            </div>
          </form>
        </Drawer>
      </div>
    </Layout>
  );
}
