import { BASE_URL } from "./index";
const token = localStorage.getItem("token");

export async function updateSingleAward({ name, orgId, id }) {
  try {
    const response = await fetch(`${BASE_URL}/ref/award/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name,
        orgId: orgId,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating organization:", error);
    throw error;
  }
}

export async function getSingleAward({ id }) {
  try {
    const response = await fetch(`${BASE_URL}/ref/award/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function createAward({ name, orgId, categoryId }) {
  try {
    const response = await fetch(`${BASE_URL}/ref/award`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name,
        organization: {
          id: orgId,
        },
        categoryId: categoryId,
      }),
    });

    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function deleteAward(id) {
  try {
    const response = await fetch(`${BASE_URL}/ref/award/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}
export async function getAwards() {
  try {
    const response = await fetch(`${BASE_URL}/ref/award`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
