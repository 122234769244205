import React, { useState } from "react";
import bg from "../assets/image.png";
import logo from "../assets/logo.svg";
import { Field, Fieldset, Input, Label, Button } from "@headlessui/react";
import { cn } from "../lib/utils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { handleLogin } from "../services/Auth";
import { Alert } from "antd";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";

export default function Auth() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [type, setType] = useState("password");
  const [reveal, setReveal] = useState(false);
  const { setUser, setToken, user } = useAuth();
  const navigate = useNavigate();
  const handleToggle = () => {
    if (type === "password") {
      setReveal(true);
      setType("text");
    } else {
      setReveal(false);
      setType("password");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      username: username,
      password: password,
    };
    handleLogin(payload)
      .then((response) => {
        if (response.result) {
          setToken(response.access_token);
          setUser(response.user);

          navigate("/requests", { replace: true });
        } else {
          setAlert(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="flex w-screen h-screen">
        <div className="overflow-hidden w-3/4 bg-filter">
          <img
            src={bg}
            alt="bg"
            className=" object-cover bg-cover w-full h-auto"
          />
        </div>
        <div className="w-1/4 flex flex-col items-center justify-center gap-8">
          <img src={logo} alt="logo" />
          {alert ? (
            <Alert message="Нэвтрэх нэр нууц үг таарахгүй байна" type="error" />
          ) : (
            ""
          )}
          <form
            className="w-10/12"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Fieldset className="flex flex-col gap-4 p-2" disabled={loading}>
              <Field>
                <Label className="text-sm/5 font-medium ">Имэйл хаяг</Label>
                <Input
                  type="email"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  className={cn(
                    "mt-3 block w-full rounded-lg text-slate-400 border py-2 px-3 text-sm/6 ",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                  )}
                  placeholder="Имэйл хаягаа энд бичнэ үү"
                />
              </Field>
              <Field>
                <Label className="text-sm/5 font-medium">Нууц үг</Label>
                <div className="mb-4 flex relative">
                  <Input
                    type={type}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className={cn(
                      "mt-3 block w-full rounded-lg text-slate-400 border py-2 px-3 text-sm/6 ",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    placeholder="Нууц үгээ бичнэ үү"
                  />
                  <span
                    className="flex justify-around items-center cursor-pointer"
                    onClick={handleToggle}
                  >
                    {reveal ? (
                      <FaEye
                        className="absolute mr-10 top-5 text-slate-600"
                        size={20}
                      />
                    ) : (
                      <FaEyeSlash
                        className="absolute mr-10 top-6 text-slate-600"
                        size={20}
                      />
                    )}
                  </span>
                </div>
              </Field>
              <Button
                type="submit"
                className=" bg-[#2C73EB] py-2 px-4 text-white rounded-lg"
              >
                Нэвтрэх
              </Button>
            </Fieldset>
          </form>
        </div>
      </div>
    </>
  );
}
