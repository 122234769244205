// import { createContext, useState } from "react";
// import axios from "axios";
// // import { BASE_URL } from "../services";
// import { BASE_URL } from "../services";
// import { checkAuthenticated, handleLogin } from "../services/Auth";
// import { message } from "antd";

// const AuthContext = createContext(null);

// export const AuthProvider = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(null);
//   const [user, setUser] = useState(() => {
//     const userProf = localStorage.getItem("user");
//     if (userProf) {
//       return JSON.parse(userProf);
//     }
//     return null;
//   });

//   // const login = async (payload) => {
//   //   try {
//   //     const response = await axios.post(`${BASE_URL}/user/login`, payload, {
//   //       withCredentials: true,
//   //     });
//   //     const data = response.json();
//   //     setUser(response.data.user);
//   //     localStorage.setItem("user", JSON.stringify(response.data.user));
//   //     localStorage.setItem("token", response.data.access_token);
//   //   } catch (error) {
//   //     //
//   //   }
//   // };
//   const login = async (payload) => {
//     try {
//       await handleLogin(payload)
//         .then((response) => {
//           if (response.result) {
//             setUser(response.user);
//             localStorage.setItem("user", JSON.stringify(response.user));
//             localStorage.setItem("token", response.access_token);
//             message.success(response.message);
//             setIsAuthenticated(true);
//             // window.location.reload();
//           } else {
//             message.error(response.message);
//             setIsAuthenticated(false);
//           }
//         })
//         .catch((error) => {
//           console.error("Error fetching auth status:", error);
//         });
//     } catch (error) {
//       //
//     }
//   };
//   // const login = async (payload) => {
//   //   try {
//   //     const response = await fetch(`${BASE_URL}/user/login`, {
//   //       method: "POST",
//   //       headers: {
//   //         "Content-type": "application/json",
//   //       },
//   //       credentials: "include",
//   //       body: JSON.stringify(payload),
//   //     });
//   //     const data = response.json();
//   //     // return data;
//   //     setUser(data?.user);
//   //     localStorage.setItem("user", data?.user);
//   //     localStorage.setItem("token", data?.access_token);
//   //   } catch (error) {
//   //     //
//   //   }
//   // };
//   const logout = async () => {
//     try {
//       const response = await axios.delete(`${BASE_URL}/user/logout`);
//       localStorage.removeItem("user");
//       setUser(null);
//       localStorage.removeItem("token");
//       localStorage.clear();
//     } catch (error) {
//       //
//     }
//   };

//   const verifyAuth = async () => {
//     try {
//       await checkAuthenticated()
//         .then((response) => {
//           console.log(response, "response-00000000000000");
//           if (response.isAuthencated) {
//             setIsAuthenticated(true);
//           } else {
//             setIsAuthenticated(false);
//             // logout();
//           }
//         })
//         .catch((error) => {
//           console.error("Error fetching auth status:", error);
//         });
//       console.log("authenticated????????????????", isAuthenticated);
//     } catch (error) {
//       console.error("ERROR AT CATCH", error);
//     }
//   };

//   return (
//     <AuthContext.Provider
//       value={{ login, user, logout, verifyAuth, isAuthenticated }}
//     >
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export default AuthContext;
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { handleLogout, checkAuthenticated } from "../services/Auth";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken_] = useState(localStorage.getItem("token"));

  const [user, setUser_] = useState(() => {
    const userProf = localStorage.getItem("user");
    if (userProf) {
      return JSON.parse(userProf);
    }
    return null;
  });

  const setUser = (newUser) => {
    setUser_(newUser);
  };
  const setToken = (newToken) => {
    setToken_(newToken);
  };
  // const [user, setUser_] = useState(() => {
  //   const userProf = localStorage.getItem("user");
  //   if (userProf) {
  //     return JSON.parse(userProf);
  //   }
  //   return null;
  // });

  const logout = async () => {
    try {
      await handleLogout();

      setToken(null);
      setUser(null);
      localStorage.removeItem("token");
    } catch (error) {
      console.error("Failed to logout:", error);

      setToken(null);
      setUser(null);
      localStorage.removeItem("token");
    }
  };
  const verifyAuth = async () => {
    try {
      await checkAuthenticated(token)
        .then((response) => {
          console.log(response, "response-00000000000000");
          if (response.isAuthencated) {
          } else {
            logout();
          }
        })
        .catch((error) => {
          console.error("Error fetching auth status:", error);
        });
    } catch (error) {
      console.error("ERROR AT CATCH", error);
    }
  };

  useEffect(() => {
    verifyAuth();
  }, []);
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
  }, [token, user]);

  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      user,
      setUser,
      logout,
    }),
    [token, user]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
