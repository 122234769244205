import { BASE_URL } from "./index";
const token = localStorage.getItem("token");

export async function getArchives() {
  try {
    const response = await fetch(`${BASE_URL}/archive`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.json();
  } catch (error) {
    throw error;
  }
}
export async function registerArchive({
  org,
  award,
  register,
  firstName,
  lastName,
  licenseNum,
  orgName,
  orderDate,
  orderNum,
  phoneNumber,
  email,
  position,
  description,
  age,
  gender,
  divisionId,
  occupationId,
  workYear,
  workDevisionYear,
}) {
  try {
    const response = await fetch(`${BASE_URL}/archive`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        awardOrgId: org,
        awardId: award,
        register: register,
        firstName: firstName,
        lastName: lastName,
        passportNumber: licenseNum,
        orgName: orgName,
        tushaalDate: orderDate,
        tushaalName: orderNum,
        phone: phoneNumber,
        email: email,
        position: position,
        description: description,
        age: age,
        gender: gender,
        divisionId: divisionId,
        occupationId: occupationId,
        workYear: workYear,
        workDevisionYear: workDevisionYear,
      }),
      credentials: "include",
    });
    console.log(response.status);
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function getSingleArchive({ id }) {
  try {
    const response = await fetch(`${BASE_URL}/archive/one/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function updateArchive({
  id,
  org,
  award,
  register,
  firstName,
  lastName,
  licenseNum,
  orgName,
  orderDate,
  orderNum,
  phoneNumber,
  email,
  position,
  description,
}) {
  console.log("Org:", org, "Award:", award);

  try {
    const response = await fetch(`${BASE_URL}/archive/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        awardOrgId: org,
        awardId: award,
        register: register,
        firstName: firstName,
        lastName: lastName,
        passportNumber: licenseNum,
        orgName: orgName,
        tushaalDate: orderDate,
        tushaalName: orderNum,
        phone: phoneNumber,
        email: email,
        position: position,
        description: description,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating organization:", error);
    throw error;
  }
}

export async function deleteSingleArchive(id) {
  try {
    const response = await fetch(`${BASE_URL}/archive/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.json();
  } catch (error) {
    console.error("Error updating organization:", error);
    throw error;
  }
}
