import { Button, message, Upload } from "antd";
import React, { useState } from "react";
import uploadbg from "../../assets/upload-04.svg";
import { UploadFile } from "../../services";
import { newRequest } from "../../services/requests";
import useFormDataStore from "../../stores/formDataStore";
import { useAuth } from "../../context/AuthContext";

export default function AttachmentForm({ next, prev }) {
  const [pendingUploads, setPendingUploads] = useState({});
  const { infoData, awardHistory, workplace, awardFiles, setAwardFiles } =
    useFormDataStore();
  const { token } = useAuth();
  // const handleUploadChange = (info, fieldName) => {
  //   const { status } = info.file;

  //   if (status === "done") {
  //     if (info.file.response && info.file.response.result === false) {
  //       message.error(info.file.response.message);
  //     } else {
  //       const newAttachment = {
  //         name: fieldName,
  //         fileName: info.file.response.filename,
  //         type: info.file.response.mimetype,
  //         url: info.file.response.path,
  //         size: info.file.response.size,
  //       };

  //       const updatedAttachments = [...awardFiles, newAttachment];
  //       setAwardFiles(updatedAttachments);
  //       message.success("Файлыг амжилттай хууллаа");
  //       console.log("Updated attachments:", awardFiles);
  //     }
  //   } else if (status === "error") {
  //     message.error("Файлыг хуулахад алдаа гарлаа");
  //   }
  // };

  const handleUploadChange = (info, fieldName) => {
    const { status } = info.file;

    if (status === "done") {
      if (info.file.response && info.file.response.result === false) {
        message.error(info.file.response.message);
      } else {
        const newAttachment = {
          name: fieldName,
          fileName: info.file.response.filename,
          type: info.file.response.mimetype,
          url: info.file.response.path,
          size: info.file.response.size,
        };

        setAwardFiles((prev) => [...prev, newAttachment]);
        message.success("Файлыг амжилттай хууллаа");
      }
    } else if (status === "error") {
      message.error("Файлыг хуулахад алдаа гарлаа");
    }
  };
  const handleBeforeUpload = (file, fieldName) => {
    setPendingUploads((prev) => ({
      ...prev,
      [fieldName]: file,
    }));
    return false;
  };
  // const handleSubmit = () => {
  //   const formData = { infoData, awardHistory, workplace, awardFiles };
  //   console.log("aaaaaaaaaaaaaaaaaaaaa----------", formData);
  //   newRequest(formData)
  //     .then((response) => {
  //       if (response.result) {
  //         console.log("aaaaaaaaaaaaaaaaaaaaa----------", formData);
  //         next();
  //       } else {
  //         message.error(response.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       message.error("aaaaaaaaaaaaaaaaaaaaa");
  //     });
  // };
  const handleSubmit = async () => {
    const uploadedFiles = [];

    const fieldNames = [
      "albanBichig",
      "hurTemdeglel",
      "todorhoilolt",
      "ndsh",
      "pastAwards",
      "shiidverHuulbar",
    ];
    for (const fieldName of fieldNames) {
      const file = pendingUploads[fieldName];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const response = await fetch(UploadFile(), {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          });

          const data = await response.json();
          if (data.result === false) {
            message.error(data.message);
          } else {
            const newAttachment = {
              name: fieldName,
              fileName: data.filename,
              type: data.mimetype,
              url: data.path,
              size: data.size,
            };
            uploadedFiles.push(newAttachment);
          }
        } catch (error) {
          message.error("Файлыг хуулахад алдаа гарлаа");
        }
      }
    }

    setAwardFiles(uploadedFiles);
    message.success("Файлууд амжилттай хуулагдлаа");
    console.log("Final attachments:", uploadedFiles);

    submitRequest();
  };
  const submitRequest = () => {
    const formData = { infoData, awardHistory, workplace, awardFiles };
    console.log("aaaaaaaaaaaaaaaaaaaaa----------", formData);
    newRequest(formData)
      .then((response) => {
        if (response.result) {
          console.log("aaaaaaaaaaaaaaaaaaaaa----------", formData);
          next();
        } else {
          message.error(response?.error?.response.message);
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("aaaaaaaaaaaaaaaaaaaaa");
      });
  };
  const handlePrev = () => {
    prev();
  };

  return (
    <div className="grid grid-cols-2  gap-6">
      <div className="flex flex-col w-full">
        <label className=" text-[13px] font-medium leading-5 text-slate-700">
          Шагнуулахаар нэр дэвшүүлсэн тухай байгууллагын албан бичиг *
        </label>
        <Upload
          onChange={(info) => handleUploadChange(info, "albanBichig")}
          // action={UploadFile()}
          accept=".pdf"
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          beforeUpload={(file) => handleBeforeUpload(file, "albanBichig")}
          name="file"
          className="border bg-white/5 rounded-lg w-full h-5/6 py-2 px-3 mt-4 text-center "
          multiple
        >
          <div className="flex flex-col m-0 justify-center items-center gap-4 pb-2">
            <img src={uploadbg} alt="uploadicon" />
            <span className="text-[14px] font-normal leading-5 text-slate-500 text-center">
              Файл хуулах
            </span>
          </div>
        </Upload>
      </div>
      <div className="flex flex-col w-full">
        <label className=" text-[13px] font-medium leading-5 text-slate-700">
          Хамт олны эсхүл удирдах зөвлөлийн хурлын тэмдэглэл *
        </label>
        <Upload
          onChange={(info) => handleUploadChange(info, "hurTemdeglel")}
          // action={UploadFile()}
          beforeUpload={(file) => handleBeforeUpload(file, "hurTemdeglel")}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          accept=".pdf"
          className="border bg-white/5 rounded-lg w-full h-5/6 py-2 px-3 mt-4 text-center "
          name="file"
          multiple
        >
          <div className="flex flex-col m-0 justify-center items-center gap-4 pb-2">
            <img src={uploadbg} alt="uploadicon" />
            <span className="text-[14px] font-normal leading-5 text-slate-500 text-center">
              Файл хуулах
            </span>
          </div>
        </Upload>
      </div>
      <div className="flex flex-col w-full">
        <label className=" text-[13px] font-medium leading-5 text-slate-700">
          Ажил байдлын тодорхойлолт /Бүтээлийн жагсаалт/ *
        </label>
        <Upload
          onChange={(info) => handleUploadChange(info, "todorhoilolt")}
          name="file"
          // action={UploadFile()}
          beforeUpload={(file) => handleBeforeUpload(file, "todorhoilolt")}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          accept=".pdf"
          className="border bg-white/5 rounded-lg w-full h-5/6 py-2 px-3 mt-4 text-center "
          multiple
        >
          <div className="flex flex-col m-0 justify-center items-center gap-4 pb-2">
            <img src={uploadbg} alt="uploadicon" />
            <span className="text-[14px] font-normal leading-5 text-slate-500 text-center">
              Файл хуулах
            </span>
          </div>
        </Upload>
      </div>
      <div className="flex flex-col w-full">
        <label className=" text-[13px] font-medium leading-5 text-slate-700">
          Нийгмийн даатгалын дэвтрийн хуулбар *
        </label>
        <Upload
          onChange={(info) => handleUploadChange(info, "ndsh")}
          name="file"
          // action={UploadFile()}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          beforeUpload={(file) => handleBeforeUpload(file, "ndsh")}
          accept=".pdf"
          className="border bg-white/5 rounded-lg w-full h-5/6 py-2 px-3 mt-4 text-center "
          multiple
        >
          <div className="flex flex-col m-0 justify-center items-center gap-4 pb-2">
            <img src={uploadbg} alt="uploadicon" />
            <span className="text-[14px] font-normal leading-5 text-slate-500 text-center">
              Файл хуулах
            </span>
          </div>
        </Upload>
      </div>
      <div className="flex flex-col w-full">
        <label className=" text-[13px] font-medium leading-5 text-slate-700">
          Өмнө шагнагдсан шагналуудын хуулбар
        </label>
        <Upload
          onChange={(info) => handleUploadChange(info, "pastAwards")}
          name="file"
          // action={UploadFile()}
          beforeUpload={(file) => handleBeforeUpload(file, "pastAwards")}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          accept=".pdf"
          multiple
          className="border bg-white/5 rounded-lg w-full h-5/6 py-2 px-3 mt-4 text-center "
        >
          <div className="flex flex-col m-0 justify-center items-center gap-4 pb-2">
            <img src={uploadbg} alt="uploadicon" />
            <span className="text-[14px] font-normal leading-5 text-slate-500 text-center">
              Файл хуулах
            </span>
          </div>
        </Upload>
      </div>
      <div className="flex flex-col w-full">
        <label className=" text-[13px] font-medium leading-5 text-slate-700">
          Онцгой амжилт гаргасан бол түүнийг гэрчлэх баримт бичиг, шийдвэрийн
          хуулбар
        </label>
        <Upload
          onChange={(info) => handleUploadChange(info, "shiidverHuulbar")}
          name="file"
          // action={UploadFile()}
          beforeUpload={(file) => handleBeforeUpload(file, "shiidverHuulbar")}
          headers={{
            Authorization: `Bearer ${token}`,
          }}
          accept=".pdf"
          multiple
          className="border bg-white/5 rounded-lg w-full h-5/6 py-2 px-3 mt-4 text-center "
        >
          <div className="flex flex-col m-0 justify-center items-center gap-4 pb-2">
            <img src={uploadbg} alt="uploadicon" />
            <span className="text-[14px] font-normal leading-5 text-slate-500 text-center">
              Файл хуулах
            </span>
          </div>
        </Upload>
      </div>
      <div className="flex gap-10">
        {/* <Button type="primary" onClick={handlePrev} style={{ marginTop: 16 }}>
          Буцах
        </Button> */}
        <Button type="primary" onClick={handleSubmit} style={{ marginTop: 16 }}>
          Илгээх
        </Button>
      </div>
    </div>
  );
}
