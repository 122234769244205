import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { updatePassword, getUserInfo } from "../services/Auth";
import { FaUserEdit } from "react-icons/fa";
import { Drawer, message } from "antd";
import { Field, Input, Label } from "@headlessui/react";
import { useAuth } from "../context/AuthContext";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";

export default function UserProfile() {
  const [userInfo, setUserInfo] = useState();
  const [open, setOpen] = useState(false);
  const { logout } = useAuth();
  const [type, setType] = useState("password");
  const [reveal, setReveal] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  function fetchUserInfo() {
    getUserInfo()
      .then((response) => setUserInfo(response))
      .catch((error) => console.log(error));
  }
  // function onClick(e) {
  //   e.preventDefault();
  //   ChangePassword()
  //     .then((response) => {
  //       if (response.result) {
  //         console.log("response", response);
  //       }
  //     })
  //     .catch((error) => console.log(error));
  // }
  const handleToggle = () => {
    if (type === "password") {
      setReveal(true);
      setType("text");
    } else {
      setReveal(false);
      setType("password");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function onSubmit(e) {
    e.preventDefault();
    console.log(formData);
    updatePassword(formData)
      .then((response) => {
        if (response.result) {
          message.success(response.message);
          setOpen(false);
          setTimeout(() => {
            message.success("Та дахин нэвтэрнэ үү!");
            logout();
          }, 1000);
          // console.log("aaaaaaaaaaaaaaaaaaaaa----------", formData);
        } else {
          message.error(response.error);
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
      });
  }
  useEffect(() => {
    fetchUserInfo();
  }, []);
  return (
    <Layout>
      <Header title="Хэрэглэгчийн мэдээлэл" />
      <div className="p-6 flex flex-col gap-6">
        <div className="grid">
          <div className="border border-black/[0.1] rounded-xl grid grid-cols-2 gap-8 py-5 px-10">
            <div className="">
              <div className="text-[#7e7e7e]">Өөрийн нэр:</div>
              <div className="">{userInfo?.firstName || ""}</div>
            </div>
            <div className="">
              <div className="text-[#7e7e7e]">Эцэг эхийн нэр:</div>
              <div className="">{userInfo?.lastName || ""}</div>
            </div>
            <div className="">
              <div className="text-[#7e7e7e]">Харьяалагдах яам:</div>
              <div className="">{userInfo?.organization?.name || ""}</div>
            </div>
            {userInfo?.subOrganization?.name && (
              <div className="">
                <div className="text-[#7e7e7e]">Байгууллага:</div>
                <div className="">{userInfo?.subOrganization?.name || ""}</div>
              </div>
            )}
            <div className="">
              <div className="text-[#7e7e7e]">Цахим шуудан:</div>{" "}
              <div className="">{userInfo?.email}</div>
            </div>
            <div className="">
              <div className="text-[#7e7e7e]">Утасны дугаар:</div>
              <div className="">{userInfo?.phone}</div>
            </div>
          </div>
        </div>
        <div
          onClick={showDrawer}
          className="flex bg-[#2c73eb] justify-start w-[200px] m-0 rounded-2xl cursor-pointer"
        >
          <div className="text-base font-medium flex items-center p-2 text-white gap-2 m-auto">
            <FaUserEdit size={20} color="white" />
            Нууц үг солих
          </div>
        </div>
        <Drawer open={open} onClose={onClose}>
          <form onSubmit={onSubmit} className="flex flex-col gap-5">
            <Field>
              <Label className="text-sm/6 font-medium text-slate-700">
                Нууц үг
              </Label>{" "}
              {/* <div className="mb-4 flex relative"> */}
              <Input
                name="oldPassword"
                type="password"
                placeholder="Утга бичнэ үү"
                className="inputStyle "
                onChange={handleChange}
              />
              {/* <span
                  className="flex justify-around items-center cursor-pointer"
                  onClick={handleToggle}
                >
                  {reveal ? (
                    <FaEye
                      className="absolute mr-10 top-5 text-slate-600"
                      size={25}
                    />
                  ) : (
                    <FaEyeSlash
                      className="absolute mr-10 top-5 text-slate-600"
                      size={25}
                    />
                  )}
                </span>
              </div> */}
            </Field>
            <Field>
              <Label className="text-sm/6 font-medium text-slate-700">
                Шинэ нууц үг
              </Label>{" "}
              {/* <div className="mb-4 flex relative"> */}
              <Input
                name="newPassword"
                type="password"
                placeholder="Утга бичнэ үү"
                className="inputStyle "
                onChange={handleChange}
              />{" "}
              {/* <span
                  className="flex justify-around items-center cursor-pointer"
                  onClick={handleToggle}
                >
                  {reveal ? (
                    <FaEye
                      className="absolute mr-10 top-5 text-slate-600"
                      size={25}
                    />
                  ) : (
                    <FaEyeSlash
                      className="absolute mr-10 top-5 text-slate-600"
                      size={25}
                    />
                  )}
                </span>
              </div> */}
            </Field>
            <Field>
              <Label className="text-sm/6 font-medium text-slate-700">
                Шинэ нууц үг давтах
              </Label>
              {/* <div className="mb-4 flex relative"> */}
              <Input
                type="password"
                name="confirmPassword"
                placeholder="Утга бичнэ үү"
                className="inputStyle "
                onChange={handleChange}
              />{" "}
              {/* <span
                  className="flex justify-around items-center cursor-pointer"
                  onClick={handleToggle}
                >
                  {reveal ? (
                    <FaEye
                      className="absolute mr-10 top-5 text-slate-600"
                      size={25}
                    />
                  ) : (
                    <FaEyeSlash
                      className="absolute mr-10 top-5 text-slate-600"
                      size={25}
                    />
                  )}
                </span>
              </div> */}
            </Field>
            <button
              type="submit"
              className="inline-flex mt-5 text-center items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
            >
              Хадгалах
            </button>
          </form>
        </Drawer>
      </div>
    </Layout>
  );
}
