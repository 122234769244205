import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { deleteAward, getAwards } from "../services/awards";
import { ConfigProvider, Drawer, Table, message } from "antd";
import { useNavigate } from "react-router-dom";
import { MdOutlineEdit, MdDelete } from "react-icons/md";
import { PiDotsThreeCircleFill } from "react-icons/pi";
import plus from "../assets/plus-sign.svg";
import { Button, Field, Fieldset, Input, Label } from "@headlessui/react";
import { cn } from "../lib/utils";
import { createAward } from "../services/awards";
import { useAuth } from "../context/AuthContext";
import { getAwardCategory } from "../services/requests";
import { FaChevronDown } from "react-icons/fa";

export default function Awards() {
  const { user } = useAuth();
  const [awards, setAwards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [showMore, setShowMore] = useState();
  const [awardCategory, setAwardCategory] = useState();
  const [formValues, setFormValues] = useState({
    name: "",
    orgId: user.orgId,
    categoryId: null,
  });

  function addAward() {
    return createAward(formValues)
      .then((response) => {
        if (response.statusCode === 201) {
          message.success("Шагнал амжилттай нэмэгдлээ");
          // navigate("/awards");
          setOpen(false);
        } else {
          console.error(response.message);
          message.error(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    await addAward();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  async function fetchAwards() {
    const response = await getAwards();
    console.log("response", response);
    setAwards(response.data);
  }
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const toggleMenu = () => {
    setShowMore(!showMore);
  };
  useEffect(() => {
    fetchAwards();
    fetchAwardCategory();
  }, []);
  const removeAward = async (id) => {
    try {
      const response = await deleteAward(id);
      if (response.result) {
        setAwards((prevData) => prevData.filter((item) => item.id !== id));
        console.log("Successfully deleted");
        message.success("Амжилттай устгагдлаа");
      } else {
        message.error("Устгахад алдаа гарлаа");
      }
    } catch (error) {
      message.error("Алдаа гарлаа. Та дахин оролдоно уу.");
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Байгууллагын нэр",
      key: "organization",
      dataIndex: "organization",
      render: (_, record) => record.organization?.name,
    },
    {
      title: "Үйлдэл",
      key: "action",
      dataIndex: "action",
      render: (_, record) => (
        <div style={{ position: "relative", display: "inline-block" }}>
          {!showMore && (
            <button
              onClick={toggleMenu}
              className="font-bold transition-all duration-300 ease-in"
            >
              <PiDotsThreeCircleFill size={25} color="black" fill="#ccc" />
            </button>
          )}

          {showMore && (
            <div className="flex gap-2 items-center justify-between">
              <button onClick={() => navigate(`/awards/edit/${record.id}`)}>
                <MdOutlineEdit size={25} />
              </button>
              <button onClick={() => removeAward(record.id)}>
                <MdDelete size={25} fill="" color="red" />
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];
  function fetchAwardCategory() {
    const orgId = user.orgId;
    getAwardCategory(orgId)
      .then((response) => {
        if (response.result) {
          console.log("responseeeeeeeeeeeeeeeeeee", response.data);
          setAwardCategory(response.data);
        }
      })
      .catch((error) => {});
  }
  const showTotal = (total) => (
    <div className="font-semibold">Нийт - {total}</div>
  );

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  return (
    <Layout>
      <Header title="Шагналын төрлүүд" />
      <div className="p-6 flex flex-col gap-6">
        <span className="flex justify-end items-center">
          <button
            className="pl-4 flex items-center gap-[10px] pr-5 py-2 text-white bg-[#2C73EB] rounded-xl  text-center"
            onClick={showDrawer}
          >
            <img src={plus} alt="" />
            <span className="text-sm font-bold">Шинэ бүртгэл</span>
          </button>
        </span>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Golos text",
              colorText: "#334155",
              fontWeightStrong: 600,
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={awards}
            scroll={{ x: "100%" }}
            rowKey="id"
            pagination={{
              showTotal: showTotal,
              current: currentPage,
              pageSize: pageSize,
              onChange: handlePageChange,
              showSizeChanger: true,
              position: ["bottomLeft"],
              locale: { items_per_page: "" },
              pageSizeOptions: ["5", "10", "20", "50"],
              onShowSizeChange: (current, size) => setPageSize(size),
            }}
          />
        </ConfigProvider>
        <Drawer open={open} onClose={onClose}>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Fieldset className="gap-6 rounded-xl bg-white/5">
              <div className="w-full gap-10 flex flex-col">
                <Field>
                  <Label>Салбар сонгох</Label>
                  <div className="relative">
                    <select
                      className={cn(
                        "mt-3 text-[#475569] block w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                        " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                      name="categoryId"
                      onChange={(e) => handleSelectChange(e, "categoryId")}
                    >
                      <option value="" disabled selected hidden>
                        Салбар сонгоно уу
                      </option>
                      {awardCategory &&
                        awardCategory.map((category) => (
                          <option value={category.id} key={category.id}>
                            {category.name}
                          </option>
                        ))}
                    </select>
                    <FaChevronDown
                      color="#475569"
                      className=" group pointer-events-none absolute top-4 right-3 size-3 "
                    />
                  </div>
                </Field>
                <Field>
                  <Label>Шагналын нэр</Label>
                  <Input
                    name="name"
                    placeholder="Утга бичнэ үү"
                    onChange={handleChange}
                    className={cn(
                      "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                  />
                </Field>
              </div>
              <Button
                type="submit"
                className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                Хадгалах
              </Button>
            </Fieldset>
          </form>
        </Drawer>
      </div>
    </Layout>
  );
}
