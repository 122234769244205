import { BASE_URL } from "./index";
const token = localStorage.getItem("token");
// export async function handleLogin({ username, password }) {
//   try {
//     const response = await fetch(`${BASE_URL}/user/login`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         username: username,
//         password: password,
//       }),
//       credentials: "include",
//     });
//     console.log(response.status);
//     return response;
//   } catch (error) {
//     throw error;
//   }
// }
export async function handleLogout() {
  try {
    const response = await fetch(`${BASE_URL}/user/logout`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response.status);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function checkAuthenticated(token) {
  try {
    const response = await fetch(`${BASE_URL}/user/check/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function getRoles() {
  try {
    const response = await fetch(`${BASE_URL}/user/role`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}
export async function getUserInfo() {
  try {
    const response = await fetch(`${BASE_URL}/user/info`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function handleLogin(payload) {
  try {
    const response = await fetch(`${BASE_URL}/user/login`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}
export async function updatePassword(formData) {
  try {
    const response = await fetch(`${BASE_URL}/user/password`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}
