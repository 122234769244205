import Header from "../components/Header";
import { Layout } from "../components/Layout";
import React, { useEffect, useState } from "react";
import {
  addHoliday,
  deleteHoliday,
  getSingleHoliday,
  holidayList,
  updateHoliday,
} from "../services";
import { Field, Input, Label } from "@headlessui/react";
import plus from "../assets/plus-sign.svg";
import { ConfigProvider, Table, Drawer, message, DatePicker } from "antd";
import moment from "moment";
import { useAuth } from "../context/AuthContext";
import { MdDelete, MdOutlineEdit } from "react-icons/md";
import { PiDotsThreeCircleFill } from "react-icons/pi";

export default function Holidays() {
  const { user } = useAuth();
  const [holidays, setHolidays] = useState();
  const [showMore, setShowMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [edit, setEdit] = useState(false);
  const [beginDate, setBeginDate] = useState();
  const [finishDate, setFinishDate] = useState();
  const [holidayDetail, setHolidayDetail] = useState();
  const [currentId, setCurrentId] = useState();
  const [editValues, setEditValues] = useState({
    name: "",
    startDate: "",
    endDate: "",
    orgId: user?.orgId,
  });
  const [pageSize, setPageSize] = useState(10);
  const [formData, setFormData] = useState({
    name: "",
    startDate: "",
    endDate: "",
    orgId: user?.orgId,
  });

  const removeHoliday = async (id) => {
    try {
      const response = await deleteHoliday(id);
      if (response) {
        setHolidays((prevData) => prevData.filter((item) => item.id !== id));
        console.log("Successfully deleted");
        message.success("Амжилттай устгагдлаа");
      } else {
        message.error("Устгахад алдаа гарлаа");
      }
    } catch (error) {
      message.error("Алдаа гарлаа. Та дахин оролдоно уу.");
    }
  };

  const toggleMenu = () => {
    setShowMore(!showMore);
  };
  function fetchHolidays() {
    holidayList()
      .then((response) => {
        console.log(response.data);
        setHolidays(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchHolidays();
  }, []);
  useEffect(() => {
    if (holidayDetail) {
      setEditValues({
        name: holidayDetail?.name,
        startDate: holidayDetail?.startDate,
        endDate: holidayDetail?.endDate,
      });
    }
  }, [holidayDetail]);
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Эхлэх хугацаа",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Дуусах хугацаа",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Үйлдэл",
      key: "action",
      dataIndex: "action",
      render: (_, record) => (
        <div style={{ position: "relative", display: "inline-block" }}>
          {!showMore && (
            <button
              onClick={toggleMenu}
              className="font-bold transition-all duration-300 ease-in"
            >
              <PiDotsThreeCircleFill size={25} color="black" fill="#ccc" />
            </button>
          )}

          {showMore && (
            <div className="flex gap-2 items-center justify-between">
              <button onClick={() => showEdit(record?.id)}>
                <MdOutlineEdit size={25} />
              </button>
              <button onClick={() => removeHoliday(record?.id)}>
                <MdDelete size={25} fill="" color="red" />
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const showEdit = (id) => {
    setEdit(true);
    setCurrentId(id);
    getSingleHoliday(id)
      .then((response) => {
        setHolidayDetail(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const closeEdit = () => {
    setEdit(false);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleDateChange = (fieldName, index, date, dateString) => {
    setFormData((prev) => ({ ...prev, [fieldName]: dateString }));
  };
  const handleChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      name: value,
    }));
  };
  const handleEdit = (event) => {
    const { value } = event.target;
    setEditValues((prevDetail) => ({
      ...prevDetail,
      name: value,
    }));
  };
  const handleEditDate = (fieldName, index, date, dateString) => {
    setEditValues((prevDetail) => ({
      ...prevDetail,
      [fieldName]: dateString,
    }));
  };

  // useEffect(() => {
  //   if (holidayDetail?.startDate && holidayDetail?.endDate) {
  //     setBeginDate(moment(holidayDetail?.startDate));
  //     setFinishDate(moment(holidayDetail?.endDate));
  //   }
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    addHoliday(formData)
      .then((response) => {
        if (response.result) {
          message.success(response.message);
          setOpen(false);
          fetchHolidays();
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
      });
  };

  const editSubmit = (e) => {
    e.preventDefault();
    console.log(holidayDetail);
    console.log(editValues);
    updateHoliday(currentId, editValues)
      .then((response) => {
        if (response.statusCode === 200) {
          console.log(response);
          message.success("Амжилттай засвар хийлээ.");
          setEdit(false);
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const showTotal = (total) => (
    <div className="font-semibold">Нийт - {total}</div>
  );
  return (
    <Layout>
      <Header title="Баяр тэмдэглэлт өдрүүд" />
      <div className="p-6 flex flex-col gap-6">
        <span className="flex justify-end items-center">
          <button
            className="pl-4 flex items-center gap-[10px] pr-5 py-2 text-white bg-[#2C73EB] rounded-xl  text-center"
            onClick={showDrawer}
          >
            <img src={plus} alt="" />
            <span className="text-sm font-bold">Шинэ бүртгэл</span>
          </button>
        </span>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Golos text",
              colorText: "#334155",
              fontWeightStrong: 600,
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={holidays}
            scroll={{ x: "100%" }}
            rowKey="id"
            pagination={{
              showTotal: showTotal,
              current: currentPage,
              pageSize: pageSize,
              onChange: handlePageChange,
              showSizeChanger: true,
              position: ["bottomLeft"],
              locale: { items_per_page: "" },
              pageSizeOptions: ["5", "10", "20", "50"],
              onShowSizeChange: (current, size) => setPageSize(size),
            }}
          />
        </ConfigProvider>
        <Drawer open={edit} onClose={closeEdit}>
          <form className="flex flex-col gap-4" onSubmit={editSubmit}>
            <div className="flex flex-col gap-4">
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Баяр тэмдэглэлт өдрийн нэр
                </Label>
                <Input
                  value={editValues.name}
                  name="name"
                  placeholder="Утга бичнэ үү"
                  className="inputStyle "
                  onChange={handleEdit}
                />
              </Field>
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Эхлэх огноо
                </Label>

                <DatePicker
                  name="startDate"
                  placeholder="Огноо сонгоно уу"
                  onChange={(date, dateString, index) =>
                    handleEditDate("startDate", index, date, dateString)
                  }
                  className="inputStyle"
                />
              </Field>
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Дуусах огноо
                </Label>
                <DatePicker
                  name="endDate"
                  placeholder="Огноо сонгоно уу"
                  onChange={(date, dateString, index) =>
                    handleEditDate("endDate", index, date, dateString)
                  }
                  className="inputStyle"
                />
              </Field>
            </div>
            <div className="flex justify-end mr-14">
              <button
                type="submit"
                className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                Бүртгэх
              </button>
            </div>
          </form>
        </Drawer>
        <Drawer open={open} onClose={onClose} width={720}>
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="grid grid-cols-2">
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Баяр тэмдэглэлт өдрийн нэр
                </Label>
                <Input
                  name="name"
                  placeholder="Утга бичнэ үү"
                  className="inputStyle "
                  onChange={handleChange}
                />
              </Field>
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Эхлэх огноо
                </Label>

                <DatePicker
                  name="startDate"
                  placeholder="Огноо сонгоно уу"
                  onChange={(date, dateString, index) =>
                    handleDateChange("startDate", index, date, dateString)
                  }
                  className="inputStyle"
                />
              </Field>
            </div>
            <div className="grid grid-cols-2">
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Дуусах огноо
                </Label>
                <DatePicker
                  name="startDate"
                  placeholder="Огноо сонгоно уу"
                  onChange={(date, dateString, index) =>
                    handleDateChange("endDate", index, date, dateString)
                  }
                  className="inputStyle"
                />
              </Field>
            </div>

            <div className="flex justify-end mr-14">
              <button
                type="submit"
                className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                Бүртгэх
              </button>
            </div>
          </form>
        </Drawer>
      </div>
    </Layout>
  );
}
