import { BASE_URL } from "./index";
const token = localStorage.getItem("token");

export async function getRequests() {
  try {
    const response = await fetch(`${BASE_URL}/award/request`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
export async function getSingleRequest(id) {
  try {
    const response = await fetch(`${BASE_URL}/award/request/one/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
export async function newRequest(data) {
  try {
    const response = await fetch(`${BASE_URL}/award/request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    console.log(response.status);
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function getRequestById({ id }) {
  try {
    const response = await fetch(`${BASE_URL}/award/request/one/${id}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}
export async function getAllStates() {
  try {
    const response = await fetch(`${BASE_URL}/ref/award-state`, {
      method: "GET",
      headers: {
        // "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function getStateHistory({ id }) {
  try {
    const response = await fetch(`${BASE_URL}/award/request/state/list/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function updateState(data) {
  try {
    const response = await fetch(`${BASE_URL}/award/request/save/state`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {}
}

export async function getReviewedRequests() {
  try {
    const response = await fetch(`${BASE_URL}/award/request/review`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function changeStatesByBulk(formData) {
  try {
    const response = await fetch(`${BASE_URL}/award/request/save/bulk/state`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function getAwardCategory(id) {
  try {
    const response = await fetch(`${BASE_URL}/ref/award/organization/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}
export async function getAwardsById({ id }) {
  try {
    const response = await fetch(`${BASE_URL}/ref/award/category/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function DownloadExcel({ id, id1 }) {
  try {
    const response = await fetch(
      `${BASE_URL}/award/request/excel/${id}/${id1}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `tailan.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return response;
  } catch (error) {
    throw error;
  }
}

//done
export async function DownloadPdf(id) {
  try {
    const response = await fetch(`${BASE_URL}/award/request/pdf/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `anket.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return response;
  } catch (error) {
    throw error;
  }
}

//done

export async function DownloadHavsralt(id) {
  try {
    const response = await fetch(`${BASE_URL}/award/request/havsralt/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `havsralt.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function checkByRegnum(params) {
  try {
    const response = await fetch(`${BASE_URL}/award/request/search/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}
