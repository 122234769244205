import React from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import {
  Field,
  Fieldset,
  Input,
  Label,
  Button,
  Select,
} from "@headlessui/react";
import { cn } from "../lib/utils";
import { FaChevronDown } from "react-icons/fa";
import { useState } from "react";
import { registerOrg } from "../services/organization";
import { message } from "antd";

const choices = [
  { name: "Тийм", value: true },
  { name: "Үгүй", value: false },
];

export default function CreateOrg() {
  const [selected, setSelected] = useState(choices[0].value);
  const [formValues, setFormValues] = useState({
    orgName: "",
    register: "",
    phone: "",
    email: "",
    isGiveAward: selected,
  });

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setSelected(value);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  function createOrg() {
    return registerOrg(formValues)
      .then((response) => {
        if (response.statusCode === 201) {
          message.success(response.message);
        } else if (response.statusCode !== 201) {
          message.error(response.message);
          // console.log("response--------------", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function onSubmit(e) {
    e.preventDefault();
    createOrg();
  }

  return (
    <Layout>
      <Header title="Байгууллага бүртгэх" />
      <div className="p-6">
        <form onSubmit={(e) => onSubmit(e)}>
          <Fieldset className="gap-6 rounded-xl bg-white/5 ">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <span className=" text-[#2C73EB] font-medium text-base ">
                  БАЙГУУЛЛАГЫН МЭДЭЭЛЭЛ:
                </span>
                <div className="border-b-2 border-[#2C73EB]"></div>
              </div>
              <div className="w-full  grid grid-cols-4 gap-6">
                <Field>
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Байгууллагын нэр <span className="text-red-500">*</span>
                  </Label>

                  <Input
                    value={formValues.orgName}
                    name="orgName"
                    placeholder="Утга бичнэ үү"
                    onChange={handleChange}
                    required={true}
                    className={cn(
                      "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                  />
                </Field>
                <Field>
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Байгууллагын регистр
                    <span className="text-red-500">*</span>
                  </Label>

                  <Input
                    onChange={handleChange}
                    value={formValues.register}
                    placeholder="Утга бичнэ үү"
                    type="number"
                    name="register"
                    required={true}
                    className={cn(
                      "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                  />
                </Field>

                <Field>
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Холбоо барих утас <span className="text-red-500">*</span>
                  </Label>

                  <Input
                    value={formValues.phone}
                    onChange={handleChange}
                    name="phone"
                    type="number"
                    placeholder="Утга бичнэ үү"
                    required={true}
                    className={cn(
                      "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                  />
                </Field>
                <Field>
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Цахим шуудангийн хаяг{" "}
                    <span className="text-red-500">*</span>
                  </Label>

                  <Input
                    value={formValues.email}
                    onChange={handleChange}
                    placeholder="Утга бичнэ үү"
                    name="email"
                    required={true}
                    className={cn(
                      "mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                  />
                </Field>
                <Field>
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Шагнал олгодог эсэх <span className="text-red-500">*</span>
                  </Label>
                  <div className="relative">
                    <Select
                      name="isGiveAward"
                      value={selected}
                      onChange={handleSelectChange}
                      defaultValue={1}
                      required={true}
                      className={cn(
                        "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                        " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                    >
                      {choices &&
                        choices.map((choice) => (
                          <option value={choice.value} key={choice.name}>
                            {choice.name}
                          </option>
                        ))}
                    </Select>
                    <FaChevronDown
                      color="#475569"
                      className=" group pointer-events-none absolute top-4 right-3 size-3 "
                    />
                  </div>
                </Field>
              </div>
            </div>

            <Button
              type="submit"
              className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
            >
              Хадгалах
            </Button>
          </Fieldset>
        </form>
      </div>
    </Layout>
  );
}
