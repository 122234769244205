import { create } from "zustand";

// let requestData = {
//   infoData: [],
//   awardHistory: [],
//   workplace: [],
//   awardFiles: [],
// };
// export function saveRequestData(data) {
//   requestData = { ...requestData, ...data };
// }

// export function getRequestData() {
//   return requestData;
// }

// export function clearRequestData() {
//   requestData = {};
// }

const useFormDataStore = create((set) => ({
  infoData: {},
  awardHistory: [],
  workplace: [],
  awardFiles: [],
  setInfoData: (data) => set({ infoData: data }),
  setAwardHistory: (data) => set({ awardHistory: data }),
  setWorkplace: (data) => set({ workplace: data }),
  setAwardFiles: (data) => set({ awardFiles: data }),
  clearRequest: () =>
    set({
      infoData: {},
      awardHistory: [],
      workplace: [],
      awardFiles: [],
    }),
}));

export default useFormDataStore;
