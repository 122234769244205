import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { ProtectedRoute } from "./ProtectRoute";
import ReviewedList from "../pages/ReviewedList";
import UserProfile from "../pages/UserProfile";
import Orders from "../pages/Orders";
import Holidays from "../pages/Holidays";
import UsersList from "../pages/UsersList";
import RequestDetail from "../pages/RequestDetail";
import Requests from "../pages/Requests";
import RequestCreate from "../pages/RequestCreate";
import EditSubOrg from "../pages/EditSubOrg";
import CreateSubOrg from "../pages/CreateSubOrg";
import SubOrgs from "../pages/SubOrgs";
import EditAward from "../pages/EditAwards";
import Awards from "../pages/Awards";
import EditOrg from "../pages/EditOrg";
import CreateOrg from "../pages/CreateOrg";
import Register from "../pages/Register";
import Archive from "../pages/Archive";
import Create from "../pages/Create";
import EditArchive from "../pages/EditArchive";
import VerifyUser from "./VerifyUser";
import Auth from "../pages/Auth";
import Report from "../pages/Report";
import Regnumsearch from "../pages/Regnumsearch";

const Routes = () => {
  const { token, user } = useAuth();

  const routesForPublic = [
    {
      path: "/user/verify",
      element: <VerifyUser />,
    },
  ];

  let routesForAuthenticatedOnly = [];

  if (user?.refRole?.name === "admin") {
    routesForAuthenticatedOnly = [
      {
        path: "/",
        element: <ProtectedRoute />,
        children: [
          {
            path: "/regnum/search",
            element: <Regnumsearch />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/auth",
            element: <Navigate to="/requests" />,
          },
          {
            path: "/",
            element: <Navigate to="/requests" />,
          },
          {
            path: "/report",
            element: <Report />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/archive",
            element: <Archive />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/archive/create",
            element: <Create />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/archive/edit/:id",
            element: <EditArchive />,
            errorElement: <div>404 not found</div>,
          },

          {
            path: "/organizations",
            element: <Register />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/organizations/create",
            element: <CreateOrg />,
            errorElement: <div>404 not found</div>,
          },

          {
            path: "/archive/edit/:id",
            element: <EditOrg />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/awards",
            element: <Awards />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/awards/edit/:id",
            element: <EditAward />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/suborgs",
            element: <SubOrgs />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/suborgs/create",
            element: <CreateSubOrg />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/suborgs/edit/:id",
            element: <EditSubOrg />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/requests/create",
            element: <RequestCreate />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/requests",
            element: <Requests />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/request/detail/:id",
            element: <RequestDetail />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/user/list",
            element: <UsersList />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/holidays/list",
            element: <Holidays />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/orders/list",
            element: <Orders />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/profile",
            element: <UserProfile />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/reviewed",
            element: <ReviewedList />,
            errorElement: <div>404 not found</div>,
          },
        ],
      },
    ];
  } else if (user?.refRole?.name === "headman") {
    routesForAuthenticatedOnly = [
      {
        path: "/",
        element: <ProtectedRoute />,
        children: [
          {
            path: "/regnum/search",
            element: <Regnumsearch />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/auth",
            element: <Navigate to="/requests" />,
          },
          {
            path: "/",
            element: <Navigate to="/requests" />,
          },
          {
            path: "/report",
            element: <Report />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/archive",
            element: <Archive />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/archive/create",
            element: <Create />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/archive/edit/:id",
            element: <EditArchive />,
            errorElement: <div>404 not found</div>,
          },

          {
            path: "/organizations",
            element: <Register />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/organizations/create",
            element: <CreateOrg />,
            errorElement: <div>404 not found</div>,
          },

          {
            path: "/archive/edit/:id",
            element: <EditOrg />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/awards",
            element: <Awards />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/awards/edit/:id",
            element: <EditAward />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/suborgs",
            element: <SubOrgs />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/suborgs/create",
            element: <CreateSubOrg />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/suborgs/edit/:id",
            element: <EditSubOrg />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/requests/create",
            element: <RequestCreate />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/requests",
            element: <Requests />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/request/detail/:id",
            element: <RequestDetail />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/user/list",
            element: <UsersList />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/holidays/list",
            element: <Holidays />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/orders/list",
            element: <Orders />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/profile",
            element: <UserProfile />,
            errorElement: <div>404 not found</div>,
          },
        ],
      },
    ];
  } else if (user?.refRole?.name === "user") {
    routesForAuthenticatedOnly = [
      {
        path: "/",
        element: <ProtectedRoute />,
        children: [
          {
            path: "/regnum/search",
            element: <Regnumsearch />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/",
            element: <Navigate to="/requests" />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/requests/create",
            element: <RequestCreate />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/request/detail/:id",
            element: <RequestDetail />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/requests",
            element: <Requests />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/profile",
            element: <UserProfile />,
            errorElement: <div>404 not found</div>,
          },
        ],
      },
    ];
  } else {
    routesForAuthenticatedOnly = [
      {
        path: "/",
        element: <ProtectedRoute />,
        children: [
          {
            path: "/regnum/search",
            element: <Regnumsearch />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/requests",
            element: <Navigate to="/reviewed" />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/request/detail/:id",
            element: <RequestDetail />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/auth",
            element: <Navigate to="/reviewed" />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/",
            element: <Navigate to="/reviewed" />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/reviewed",
            element: <ReviewedList />,
            errorElement: <div>404 not found</div>,
          },
          {
            path: "/profile",
            element: <UserProfile />,
            errorElement: <div>404 not found</div>,
          },
        ],
      },
    ];
  }

  const routesForNotAuthenticatedOnly = [
    {
      path: "/auth",
      element: <Auth />,
    },
  ];

  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  return <RouterProvider router={router} />;
};
export default Routes;
