import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import {
  DownloadExcel,
  DownloadHavsralt,
  getAllStates,
} from "../services/requests";
import { holidayList } from "../services";
import { Select } from "antd";
const { Option } = Select;

export default function Report() {
  const [holidays, setHolidays] = useState();
  const [states, setStates] = useState();
  const [chosenState, setChosenState] = useState();
  const [chosenHoliday, setChosenHoliday] = useState();
  const [havsraltId, setHavsraltId] = useState(null);

  function fetchHolidays() {
    holidayList()
      .then((response) => {
        console.log(response.data);
        setHolidays(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchStates() {
    getAllStates()
      .then((response) => {
        if (response.result) {
          console.log("states", response);
          setStates(response.data);
          console.log("states", states);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function onSubmit(e) {
    e.preventDefault();
    const payload = {
      id: chosenHoliday,
      id1: chosenState,
    };
    await DownloadExcel(payload);
  }
  async function handleHavsraltSubmit(e) {
    e.preventDefault();
    await DownloadHavsralt(havsraltId);
  }
  useEffect(() => {
    fetchHolidays();
    fetchStates();
  }, []);
  return (
    <Layout>
      <Header title="Тайлан" />
      <div className="p-6">
        <div>
          <form onSubmit={onSubmit} className="flex flex-col ">
            <label>Тайлан татах</label>
            <div className="flex gap-10 items-end">
              <Select
                placeholder="Баяр тэмдэглэлт өдөр сонгох"
                onChange={(value) => setChosenHoliday(value)}
                style={{ width: 200 }}
                allowClear
              >
                {holidays &&
                  holidays.map((holiday, index) => (
                    <Option key={index} value={holiday?.id}>
                      {holiday?.name}
                    </Option>
                  ))}
              </Select>
              <Select
                placeholder="Төлөв сонгох"
                onChange={(value) => setChosenState(value)}
                style={{ width: 200 }}
                allowClear
              >
                {states &&
                  states.map((state, index) => (
                    <Option key={index} value={state?.id}>
                      {state?.stateName}
                    </Option>
                  ))}
              </Select>
              <button
                type="submit"
                className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1 px-4 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                Татах
              </button>
            </div>
          </form>
        </div>
        <div className="mt-3">
          <form className="flex flex-col" onSubmit={handleHavsraltSubmit}>
            <label>Тушаалын хавсралт татах</label>
            <div className="flex gap-10 items-end">
              <Select
                placeholder="Баяр тэмдэглэлт өдөр сонгох"
                onChange={(value) => setHavsraltId(value)}
                style={{ width: 200 }}
                allowClear
              >
                {holidays &&
                  holidays.map((holiday, index) => (
                    <Option key={index} value={holiday.id}>
                      {holiday?.name}
                    </Option>
                  ))}
              </Select>
              <button
                type="submit"
                className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1 px-4 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                Татаж авах
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
