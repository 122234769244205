export const BASE_URL = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem("token");

export async function getAwardOrgs() {
  try {
    const response = await fetch(`${BASE_URL}/organization/isgiveawardorg`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getOrganizations() {
  try {
    const response = await fetch(`${BASE_URL}/organization`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getDivisions() {
  try {
    const response = await fetch(`${BASE_URL}/ref-division`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getOccupations() {
  try {
    const response = await fetch(`${BASE_URL}/ref-occupation`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getGenders() {
  try {
    const response = await fetch(`${BASE_URL}/api/gender`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

// export async function handleLogin({ username, password }) {
//   try {
//     const response = await fetch(`${BASE_URL}/user/login`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         username: username,
//         password: password,
//       }),
//       credentials: "include",
//     });
//     console.log(response.status);
//     return response;
//   } catch (error) {
//     throw error;
//   }
// }

export async function getEduTypes() {
  try {
    const response = await fetch(`${BASE_URL}/ref/educationtype`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching education types:", error);
    throw error;
  }
}

// export async function newRequest(data) {
//   try {
//     const response = await fetch(`${BASE_URL}/award/request`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(data),
//     });

//     return response;
//   } catch (error) {
//     throw error;
//   }
// }

export async function UploadFiles(data) {
  try {
    const response = await fetch(`${BASE_URL}/award/request/oneFile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function UploadFile() {
  const url = `${BASE_URL}/award/request/oneFile`;
  return url;
}

export async function getZahirgaa() {
  try {
    const response = await fetch(`${BASE_URL}/ref/citizenship`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      // body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function getUserList() {
  try {
    const response = await fetch(`${BASE_URL}/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
export async function getSingleUser(id) {
  try {
    const response = await fetch(`${BASE_URL}/user/one/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}
export async function addUser(formData) {
  try {
    const response = await fetch(`${BASE_URL}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function UpdatePassword(email) {
  try {
    const response = await fetch(`${BASE_URL}/user/restore`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ email }),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function DeleteUser(id) {
  try {
    const response = await fetch(`${BASE_URL}/user/${id}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}
export async function updateUser(id, data) {
  try {
    const response = await fetch(`${BASE_URL}/user/${id}`, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function changePassword(newPassword, confirmPassword, token) {
  try {
    const response = await fetch(`${BASE_URL}/user/verify`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ newPassword, confirmPassword, token }),
    });
    // const data = await response.json();
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function holidayList() {
  try {
    const response = await fetch(`${BASE_URL}/ref/holiday`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function addHoliday(formData) {
  try {
    const response = await fetch(`${BASE_URL}/ref/holiday`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
export async function deleteHoliday(id) {
  try {
    const response = await fetch(`${BASE_URL}/ref/holiday/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function updateHoliday(id, values) {
  try {
    const response = await fetch(`${BASE_URL}/ref/holiday/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(values),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function getSingleHoliday(id) {
  try {
    const response = await fetch(`${BASE_URL}/ref/holiday/one/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function OrdersList() {
  try {
    const response = await fetch(`${BASE_URL}/archive/order`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function addOrder(formData) {
  try {
    const response = await fetch(`${BASE_URL}/archive/order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function deleteOrder(id) {
  try {
    const response = await fetch(`${BASE_URL}/archive/order/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function editOrder({ id }) {
  try {
    const response = await fetch(`${BASE_URL}/archive/order/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function getSingleOrder({ id }) {
  try {
    const response = await fetch(`${BASE_URL}/archive/order/one/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function getHolidayByOrg(id) {
  try {
    const response = await fetch(`${BASE_URL}/ref/holiday/org/${id}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function getPdf(url) {
  try {
    const response = await fetch(
      `${BASE_URL}/award/request/file/${url}?keyCode=fjdkslarteywquy213234438`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getPicture(path) {
  const url = `${BASE_URL}/award/request/file/${path}?keyCode=fjdkslarteywquy213234438`;
  return url;
}
