import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { changePassword } from "../services";
import { message } from "antd";
import bg from "../assets/image.png";
import logo from "../assets/logo.svg";
import { Field, Fieldset, Input, Label, Button } from "@headlessui/react";

function VerifyUser() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [newPassword, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const navigate = useNavigate();

  async function onSubmit(e) {
    e.preventDefault();
    changePassword(newPassword, confirmPassword, token)
      .then((response) => {
        console.log(response);
        if (response.result) {
          message.success(response.messsage);
          navigate("/auth");
        } else {
          message.error(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
      });
  }

  return (
    <div className="flex w-screen h-screen">
      <div className="overflow-hidden w-3/4 bg-filter">
        <img
          src={bg}
          alt="bg"
          className=" object-cover bg-cover w-full h-auto"
        />
      </div>
      <div className="w-1/4 flex flex-col items-center justify-center gap-8">
        <img src={logo} alt="logo" />
        <form className="w-10/12" onSubmit={onSubmit}>
          <Fieldset className="flex flex-col gap-4 p-2">
            <Field>
              <Label className="text-sm/5 font-medium ">Шинэ нууц үг</Label>
              <Input
                type="password"
                className="inputStyle"
                name="newPassword"
                placeholder="Шинэ нууц үг"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Field>
            <Field>
              <Label className="text-sm/5 font-medium">
                Нууц үг давтана уу
              </Label>
              <Input
                type="password"
                className="inputStyle"
                name="confirmPassword"
                placeholder="Нууц үгээ давтана уу"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </Field>
            <Button
              type="submit"
              className=" bg-[#2C73EB] py-2 px-4 text-white rounded-lg"
            >
              Нэвтрэх
            </Button>
          </Fieldset>
        </form>
      </div>
    </div>
  );
}

export default VerifyUser;
