import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { getAllStates, getRequests } from "../services/requests";
import { Button, ConfigProvider, DatePicker, Input, Select, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { PiDotsThreeCircleFill } from "react-icons/pi";
import { IoIosEye } from "react-icons/io";
import plus from "../assets/plus-sign.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import { getAwards } from "../services/awards";
import { useAuth } from "../context/AuthContext";
import { FaSearch } from "react-icons/fa";

const { Option } = Select;

export default function Requests() {
  const { user } = useAuth();
  const [requests, setRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showMore, setShowMore] = useState();
  const [awards, setAwards] = useState();
  const [searchText, setSearchText] = useState("");
  const [states, setStates] = useState();
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedAward, setSelectedAward] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedState, setSelectedState] = useState();

  async function fetchRequests() {
    const response = await getRequests();
    setRequests(response.data);
    setFilteredDataSource(response.data);
  }
  function fetchAwards() {
    getAwards()
      .then((response) => {
        setAwards(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function fetchStates() {
    getAllStates()
      .then((response) => {
        if (response.result) {
          console.log("states", response);
          setStates(response.data);
          console.log("states", states);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    fetchRequests();
    fetchAwards();
    fetchStates();
  }, []);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setShowMore(!showMore);
  };

  const statusColors = {
    Шагнагдсан: "bg-green-500 text-white",
    Зөвшөөрсөн: "bg-green-500 text-white",
    "Хүлээн авсан": "bg-[#2c73eb] text-white",
    Илгээсэн: "bg-[#2c73eb] text-white",
    Хянасан: "bg-[#2c73eb] text-white",
    Татгалзсан: "bg-red-600 text-white",
    Буцаасан: "bg-orange-500 text-white",
    // canceled: "bg-gray-100 text-gray-800",
  };
  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      render: (_, record) => record?.key + 1,
    },
    {
      title: "Овог",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Нэр",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Шагнал",
      key: "awardTable",
      dataIndex: "awardTable",
      render: (_, record) => record.awardTable,
    },
    {
      title: "Харьяалагдах яам",
      key: "awardOrg",
      dataIndex: "awardOrg",
    },
    {
      title: "Илгээсэн байгууллага",
      key: "subOrganization",
      dataIndex: "subOrganization",
      render: (_, record) => record.subOrganization,
    },
    {
      title: "Төлөв",
      key: "awardState",
      dataIndex: "awardState",
      render: (_, record) => (
        <div
          className={`py-1 px-3 w-[100px] ${
            statusColors[record.awardState] || " "
          }
           m-0 text-center text-white rounded-xl font-semibold text-[12px]`}
        >
          {record.awardState}
        </div>
      ),
    },

    {
      title: "Илгээсэн",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Үйлдэл",
      key: "action",
      dataIndex: "action",
      render: (_, record) => (
        <div style={{ position: "relative", display: "inline-block" }}>
          {!showMore && (
            <button
              onClick={toggleMenu}
              className="font-bold transition-all duration-300 ease-in"
            >
              <PiDotsThreeCircleFill size={25} color="black" fill="#ccc" />
            </button>
          )}

          {showMore && (
            <div className="flex gap-2 items-center justify-between">
              <button onClick={() => navigate(`/request/detail/${record.id}`)}>
                <IoIosEye size={25} />
              </button>
              {/* <button onClick={() => navigate(`/requests/edit/${record.id}`)}>
                <MdOutlineEdit size={25} />
              </button> */}
              {/* <button onClick={() => removeArchive(record.id)}>
                <MdDelete size={25} fill="" color="red" />
              </button> */}
            </div>
          )}
        </div>
      ),
    },
  ];

  const dataSource =
    filteredDataSource &&
    filteredDataSource.map((_, i) => ({
      key: i,
      id: _?.id,
      firstName: _.firstName,
      lastName: _.lastName,
      awardOrg: _.awardOrg?.name,
      awardTable: _.awardTable?.name,
      subOrganization: _.subOrganization?.name,
      awardState: _?.awardState?.stateName,
      createdDate: _?.createdDate,
    }));
  const showTotal = (total) => (
    <div className="font-semibold">Нийт - {total}</div>
  );
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleSearch = () => {
    let filteredData = requests;

    if (searchText) {
      const searchTextLower = searchText.toLowerCase();
      filteredData = filteredData.filter(
        (item) =>
          item.firstName?.toLowerCase().includes(searchTextLower) ||
          item.lastName?.toLowerCase().includes(searchTextLower) ||
          item.awardOrg?.name?.toLowerCase().includes(searchTextLower) ||
          item.subOrganization?.name?.toLowerCase().includes(searchTextLower)
      );
    }

    if (selectedAward) {
      filteredData = filteredData.filter(
        (item) => item.awardTable?.name === selectedAward
      );
    }
    if (selectedState) {
      filteredData = filteredData.filter(
        (item) => item.awardState?.stateName === selectedState
      );
    }
    if (selectedDate) {
      const selectedTime = new Date(selectedDate).setHours(0, 0, 0, 0);
      filteredData = filteredData.filter((item) => {
        const itemTime = new Date(item.createdDate).setHours(0, 0, 0, 0);
        return itemTime === selectedTime;
      });
    }

    setFilteredDataSource(filteredData);
  };
  return (
    <>
      <Layout>
        <Header title="Хүсэлтийн жагсаалт" />
        <div className="p-6 flex flex-col gap-6">
          <div className="flex justify-between">
            <div className="flex gap-4 items-center">
              <Input
                placeholder="Овог,нэр,регистрийн дугаараар хайх"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{ width: 300 }}
              />
              <Select
                placeholder="Шагнал сонгох"
                value={selectedAward || undefined}
                onChange={(value) => setSelectedAward(value)}
                style={{ width: 200 }}
                allowClear
              >
                {awards &&
                  awards.map((award) => (
                    <Option key={award.id} value={award.name}>
                      {award.name}
                    </Option>
                  ))}
              </Select>
              <Select
                placeholder="Төлөв сонгох"
                value={selectedState || undefined}
                onChange={(value) => setSelectedState(value)}
                style={{ width: 200 }}
                allowClear
              >
                {states &&
                  states.map((state) => (
                    <Option key={state.id} value={state.stateName}>
                      {state.stateName}
                    </Option>
                  ))}
              </Select>
              <DatePicker
                placeholder="Огноо сонгох"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                style={{ width: 200 }}
                allowClear
              />
              <Button type="primary" onClick={handleSearch}>
                <FaSearch color="white" size={16} />
              </Button>
            </div>

            <span className="flex justify-end items-center">
              {user?.refRole?.name === "chiefman" ? (
                ""
              ) : (
                <Link
                  to="/requests/create"
                  className="pl-4 flex items-center gap-[10px] pr-5 py-2 text-white bg-[#2C73EB] rounded-xl  text-center"
                >
                  <img src={plus} alt="" />
                  <span className="text-sm font-bold">Шинэ бүртгэл</span>
                </Link>
              )}
            </span>
          </div>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: "Golos text",
                colorText: "#334155",
                fontWeightStrong: 600,
              },
            }}
          >
            <Table
              columns={columns}
              dataSource={dataSource && dataSource}
              scroll={{ x: "100%" }}
              rowKey="id"
              pagination={{
                showTotal: showTotal,
                current: currentPage,
                pageSize: pageSize,
                onChange: handlePageChange,
                showSizeChanger: true,
                position: ["bottomRight"],
                locale: { items_per_page: "" },
                pageSizeOptions: ["5", "10", "20", "50"],
                onShowSizeChange: (current, size) => setPageSize(size),
              }}
            />
          </ConfigProvider>
        </div>
      </Layout>
    </>
  );
}
