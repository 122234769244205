import { BASE_URL } from "./index";
const token = localStorage.getItem("token");

export async function registerOrg({
  orgName,
  register,
  phone,
  email,
  isGiveAward,
}) {
  try {
    const response = await fetch(`${BASE_URL}/organization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: orgName,
        register: register,
        phone: phone,
        email: email,
        isGiveAward: isGiveAward,
      }),
      credentials: "include",
    });
    console.log(response, "fetch function response");
    return response.json();
  } catch (error) {
    throw error;
  }
}
export async function getSingleOrg({ id }) {
  try {
    const response = await fetch(`${BASE_URL}/organization/one/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateOrg({
  id,
  orgName,
  register,
  phone,
  email,
  isGiveAward,
}) {
  try {
    const response = await fetch(`${BASE_URL}/organization/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: orgName,
        register: register,
        phone: phone,
        email: email,
        isGiveAward: isGiveAward,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating organization:", error);
    throw error;
  }
}
