import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import {
  Alert,
  Button,
  ConfigProvider,
  Descriptions,
  Drawer,
  message,
  Modal,
  Steps,
  Table,
} from "antd";
import { useParams } from "react-router-dom";
import {
  DownloadPdf,
  getAllStates,
  getRequestById,
  getStateHistory,
  updateState,
} from "../services/requests";
import { getPdf, getPicture, OrdersList } from "../services";
import { Field, Label, Textarea } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa";
import { cn } from "../lib/utils";
import moment from "moment";
import { useAuth } from "../context/AuthContext";
import { IoCloseOutline } from "react-icons/io5";
import AnketPdf from "../components/AnketPdf";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default function RequestDetail() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [url, setUrl] = useState();
  const [detail, setDetail] = useState();
  const { id } = useParams();
  const [states, setStates] = useState([]);
  const [history, setHistory] = useState([]);
  const [orders, setOrders] = useState([]);
  const [current, setCurrent] = useState();
  const [selectedState, setSelectedState] = useState();
  const [imgSrc, setImgSrc] = useState();
  const [showOrder, setShowOrder] = useState(false);
  const [formData, setFormData] = useState({
    stateId: null,
    orderId: null,
    archiveId: null,
    passportNumber: null,
    comment: "",
  });

  function fetchDetails() {
    getRequestById({ id })
      .then((response) => {
        if (response.result) {
          setDetail(response);
          setLoading(false);
          getImage(response?.infoData?.picture);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchStates() {
    getAllStates()
      .then((response) => {
        if (response.result) {
          console.log("states", response);
          setStates(response.data);
          console.log("states", states);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function fetchStateHistory() {
    getStateHistory({ id })
      .then((response) => {
        if (response.result) {
          console.log("states", response);
          setHistory(response.data);
          setCurrent(history.at(-1));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function downloadCv() {
    await DownloadPdf(id);
  }
  function fetchOrders() {
    OrdersList()
      .then((response) => {
        if (response.result) {
          setOrders(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getImage(path) {
    getPicture(path)
      .then((response) => {
        setImgSrc(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    fetchOrders();
    fetchDetails();
    fetchStates();
    fetchStateHistory();
  }, []);
  const awardColumns = [
    {
      title: "Шагналын нэр",
      dataIndex: "awardName",
      key: "awardName",
      render: (text) => text || "-",
    },
    {
      title: "Шагнагдсан огноо",
      dataIndex: "giveDate",
      key: "giveDate",
      render: (text) => text || "-",
    },
  ];
  const workplaceColumns = [
    {
      title: "Ажлын газрын нэр",
      dataIndex: "workName",
      key: "workName",
      render: (text) => text || "-",
    },
    {
      title: "Эрхэлсэн ажил",
      dataIndex: "workPosition",
      key: "workPosition",
      render: (text) => text || "-",
    },
    {
      title: "Ажиллаж эхэлсэн огноо",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => text || "-",
    },
    {
      title: "Ажлаас гарсан огноо",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => text || "-",
    },
  ];
  const orderUrl = orders.at(-1);
  const OpenOrder = (url) => {
    setShowOrder(true);
    renderBlob(url);
    // renderBlob("9ef75185-afb2-4397-961d-2f8d8815f405.pdf");
  };
  const OpenFile = (url) => {
    setOpen(true);
    renderBlob(url);
  };
  const renderBlob = async (url) => {
    const response = await getPdf(url);
    const blob = await response.blob();
    setUrl(URL.createObjectURL(blob));
  };

  const onChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      comment: value,
    }));
  };
  const onChoice = (e, value) => {
    e.preventDefault();
    setOpenDrawer(true);
    setFormData((prev) => ({
      ...prev,
      stateId: value,
      archiveId: detail?.infoData?.id,
    }));
    fetchDetails();
    fetchStateHistory();
    setSelectedState(value);
    console.log(formData);
  };
  const onClose = () => {
    setOpenDrawer(false);
  };
  const onReceive = (e) => {
    e.preventDefault();
    const payload = {
      stateId: 1,
      archiveId: detail?.infoData?.id,
      orderId: null,
      comment: "",
    };
    updateState(payload)
      .then((response) => {
        if (response.result) {
          message.success("Төлөв амжилттай солигдлоо");
          setOpenDrawer(false);
          setSelectedState(1);
          fetchDetails();
          fetchStateHistory();
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
      });
  };
  const orderChoice = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      orderId: value,
    }));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    updateState(formData)
      .then((response) => {
        if (response.result) {
          message.success("Төлөв амжилттай солигдлоо");
          setOpenDrawer(false);
          fetchStateHistory();
          fetchDetails();
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
      });
  };
  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save("anket.pdf");
    });
  };
  return (
    <Layout>
      <Header title="Хүсэлтийн дэлгэрэнгүй" />
      <div className="p-6 flex flex-col gap-14 overflow-hidden">
        <div className="relative">
          <Steps
            direction="horizontal"
            progressDot
            current={detail?.infoData?.awardStateId}
            items={history.map((item) => ({
              title: item?.refAwardState?.stateName || "-",
              subTitle:
                item?.createdUser?.firstName +
                " " +
                item?.createdUser?.lastName,
              description: (
                <div>
                  {moment(item?.createdDate).format("MM-DD-YYYY h:mm a") || "-"}
                </div>
              ),
            }))}
          />
          {detail?.infoData?.awardState?.id === "4" && (
            <button
              onClick={() => OpenOrder(orderUrl?.fileName)}
              className="flex justify-end bg-[#2563eb] text-white px-3 py-1 rounded-md text-[14px] items-end absolute right-[1%]"
            >
              Тушаал харах
            </button>
          )}
        </div>
        <div className="flex justify-start items-end gap-10">
          {user.refRole?.name !== "user" ? (
            <div className="flex items-center gap-5">
              {user.refRole?.name !== "chiefman" ? (
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        defaultBg: "#2563eb",
                        defaultHoverBg: "#60a5fa",
                        defaultHoverColor: "#fff",
                      },
                    },
                  }}
                >
                  <Button
                    disabled={
                      detail?.infoData?.awardState?.id !== "2" ||
                      detail?.infoData?.awardState?.id === "4" ||
                      detail?.infoData?.awardState?.id === "7" ||
                      detail?.infoData?.awardState?.id === "5" ||
                      detail?.infoData?.awardState?.id === "3"
                        ? true
                        : false
                    }
                    onClick={onReceive}
                    className="p-5 rounded-lg border-none font-medium text-white"
                  >
                    Хүлээн авах
                  </Button>
                </ConfigProvider>
              ) : (
                ""
              )}
              {user.refRole?.name === "chiefman" ? (
                ""
              ) : (
                <>
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          defaultBg: "#2563eb",
                          defaultHoverBg: "#60a5fa",
                          defaultHoverColor: "#fff",
                        },
                      },
                    }}
                  >
                    <Button
                      disabled={
                        detail?.infoData?.awardState?.id === "8" ||
                        detail?.infoData?.awardState?.id === "4" ||
                        detail?.infoData?.awardState?.id === "7" ||
                        detail?.infoData?.awardState?.id === "5" ||
                        detail?.infoData?.awardState?.id === "3"
                          ? true
                          : false
                      }
                      onClick={(e) => {
                        onChoice(e, 8);
                      }}
                      className="text-sm font-medium text-white p-5 border-none rounded-lg"
                    >
                      Хянасан
                    </Button>
                  </ConfigProvider>
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          defaultBg: "#dc2626",
                          defaultHoverBg: "#f87171",
                          defaultHoverColor: "#fff",
                        },
                      },
                    }}
                  >
                    <Button
                      disabled={
                        detail?.infoData?.awardState?.stateName === "2" ||
                        detail?.infoData?.awardState?.id === "7" ||
                        detail?.infoData?.awardState?.id === "5" ||
                        detail?.infoData?.awardState?.id === "3" ||
                        detail?.infoData?.awardState?.id === "4"
                          ? true
                          : false
                      }
                      onClick={(e) => {
                        onChoice(e, 3);
                      }}
                      className="text-sm font-medium text-white p-5 bg-red-600 hover:bg-red-400 border-none rounded-lg"
                    >
                      Татгалзах
                    </Button>
                  </ConfigProvider>
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          defaultBg: "#ea580c",
                          defaultHoverBg: "#fb923c",
                          defaultHoverColor: "#fff",
                        },
                      },
                    }}
                  >
                    <Button
                      disabled={
                        detail?.infoData?.awardState?.id === "2" ||
                        detail?.infoData?.awardState?.id === "7" ||
                        detail?.infoData?.awardState?.id === "5" ||
                        detail?.infoData?.awardState?.id === "3" ||
                        detail?.infoData?.awardState?.id === "4"
                          ? true
                          : false
                      }
                      onClick={(e) => {
                        onChoice(e, 5);
                      }}
                      className="text-sm font-medium text-white p-5 border-none rounded-lg"
                    >
                      Буцаах
                    </Button>
                  </ConfigProvider>
                </>
              )}
              {user.refRole?.name === "chiefman" ||
              user.refRole?.name === "admin" ? (
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        defaultBg: "#22c55e",
                        defaultHoverBg: "#86efac",
                        defaultHoverColor: "#fff",
                      },
                    },
                  }}
                >
                  <Button
                    disabled={
                      detail?.infoData?.awardState?.id === "7" ||
                      detail?.infoData?.awardState?.id === "4" ||
                      detail?.infoData?.awardState?.id === "7" ||
                      detail?.infoData?.awardState?.id === "5" ||
                      detail?.infoData?.awardState?.id === "3"
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      onChoice(e, 7);
                    }}
                    className="text-sm font-medium text-white py-5 px-5  border-none rounded-lg"
                  >
                    Зөвшөөрөх
                  </Button>
                </ConfigProvider>
              ) : (
                ""
              )}
              {user.refRole?.name === "headman" ||
              user.refRole?.name === "admin" ? (
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        defaultBg: "#22c55e",
                        defaultHoverBg: "#86efac",
                        defaultHoverColor: "#fff",
                      },
                    },
                  }}
                >
                  <Button
                    disabled={
                      detail?.infoData?.awardState?.id === "2" ||
                      detail?.infoData?.awardState?.id === "4" ||
                      detail?.infoData?.awardState?.id === "5" ||
                      detail?.infoData?.awardState?.id === "3"
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      onChoice(e, 4);
                    }}
                    className="text-sm font-medium text-white p-5 border-none rounded-lg"
                  >
                    Шагнагдсан
                  </Button>
                </ConfigProvider>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        {history.at(-1)?.comment && (
          <div className="flex flex-col gap-4 justify-start items-start max-w-[550px]">
            <div className="font-semibold text-[16px] ">
              {detail?.infoData?.awardState?.stateName} төлөвийн тайлбар:
            </div>
            {detail?.infoData?.awardState?.id === "3" ||
            detail?.infoData?.awardState?.id === "5" ||
            detail?.infoData?.awardState?.id === "7" ||
            detail?.infoData?.awardState?.id === "4" ? (
              <Alert message={history.at(-1)?.comment} type="info" />
            ) : (
              ""
            )}
          </div>
        )}
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-4">
            <div className="font-semibold text-[16px] text-[rgba(0,0,0,0.88)]">
              Шагнал:
            </div>
            <Descriptions bordered>
              <Descriptions.Item label="Шагналын нэр">
                {(detail &&
                  detail?.infoData &&
                  detail?.infoData?.awardTable &&
                  detail?.infoData?.awardTable?.name) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Шагналын төрөл">
                {(detail &&
                  detail?.infoData &&
                  detail?.infoData?.refAwardCategory &&
                  detail?.infoData?.refAwardCategory.name) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Харьяалагдах яам">
                {(detail &&
                  detail?.infoData &&
                  detail?.infoData?.awardOrg &&
                  detail?.infoData?.awardOrg?.name) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Баяр тэмдэглэлт өдөр">
                {(detail &&
                  detail?.infoData &&
                  detail?.infoData?.refHoliday?.name) ||
                  "-"}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className="flex flex-col gap-4">
            <div className="font-semibold text-[16px] text-[rgba(0,0,0,0.88)]">
              ХУВИЙН МЭДЭЭЛЭЛ:
            </div>
            <Descriptions bordered>
              <Descriptions.Item label="Зураг">
                <img
                  src={imgSrc}
                  alt="зураг"
                  className="max-w-[100px] max-h-[100px]"
                />
              </Descriptions.Item>
              <Descriptions.Item label="Овог">
                {(detail && detail?.infoData && detail?.infoData?.lastName) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Нэр">
                {(detail && detail?.infoData && detail?.infoData?.firstName) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Төрсөн огноо">
                {(detail && detail?.infoData && detail?.infoData?.birthDate) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Регистрийн дугаар">
                {(detail && detail?.infoData && detail?.infoData?.register) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Хүйс">
                {(detail &&
                  detail?.infoData &&
                  detail?.infoData?.refGender?.name) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Боловсрол">
                {(detail &&
                  detail?.infoData &&
                  detail?.infoData?.refEducationType?.name) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Мэргэжил">
                {(detail &&
                  detail?.infoData &&
                  detail?.infoData?.refOccupation?.name) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Салбарт ажилласан жил">
                {(detail &&
                  detail?.infoData &&
                  detail.infoData?.workDevisionYear) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Одоо хашиж буй албан тушаал">
                {(detail && detail?.infoData && detail?.infoData?.position) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Нийт ажилласан жил">
                {(detail && detail?.infoData && detail?.infoData?.workYear) ||
                  "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Үндсэн захиргаа">
                {(detail &&
                  detail?.infoData &&
                  detail?.infoData?.refCitizenShip?.name) ||
                  "-"}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className="flex flex-col gap-4">
            <div className="font-semibold text-[16px] leading-normal text-[rgba(0,0,0,0.88)]">
              ТӨР, ЗАСГИЙН БОЛОН БУСАД ЯМАР ШАГНАЛААР ХЭЗЭЭ ШАГНАГДСАН:
            </div>
            <Table columns={awardColumns} dataSource={detail?.awardHistory} />
          </div>
          <div className="flex flex-col gap-4">
            <div className="font-semibold text-[16px] leading-normal text-[rgba(0,0,0,0.88)]">
              УРЬД БОЛОН ОДОО АЖИЛЛАЖ БАЙГАА ГАЗАР:
            </div>
            <Table columns={workplaceColumns} dataSource={detail?.workplace} />
          </div>
          <div className="flex flex-col gap-4">
            <div className="font-semibold text-[16px] leading-normal text-[rgba(0,0,0,0.88)]">
              Файл хавсралт
            </div>
            <Descriptions bordered>
              <Descriptions.Item span={3} label={"Анкет"}>
                {/* <Button type="primary" onClick={downloadCv}>
                  Анкет татах
                </Button> */}
                <Button type="primary" onClick={printDocument}>
                  Анкет татах
                </Button>
                {/* <PDFDownloadLink
                  document={
                    <VerticalTableDocument data={detail} src={imgSrc} />
                  }
                  fileName="anket"
                >
                  <Button type="primary">Анкет татах</Button>
                </PDFDownloadLink> */}
              </Descriptions.Item>
              {detail?.awardFiles.map((item, index) => {
                let label = "";
                if (item.name === "albanBichig") {
                  label = "Албан бичиг";
                } else if (item.name === "todorhoilolt") {
                  label = "Ажил байдлын тодорхойлолт";
                } else if (item.name === "pastAwards") {
                  label = "Өмнө шагнагдсан шагналуудын хуулбар";
                } else if (item.name === "hurTemdeglel") {
                  label = "Хамт олны эсхүл удирдах зөвлөлийн хурлын тэмдэглэл";
                } else if (item.name === "ndsh") {
                  label = "Нийгмийн даатгалын дэвтрийн хуулбар";
                } else if (item.name === "shiidverHuulbar") {
                  label =
                    "Онцгой амжилт гаргасан бол түүнийг гэрчлэх баримт бичиг, шийдвэрийн хуулбар";
                } else {
                  label = "-";
                }
                return (
                  <>
                    {item.fileName === null ? (
                      "-"
                    ) : (
                      <Descriptions.Item span={3} key={index} label={label}>
                        <Button
                          type="primary"
                          onClick={() => OpenFile(item.fileName)}
                        >
                          Файл харах
                        </Button>
                      </Descriptions.Item>
                    )}
                  </>
                );
              })}
            </Descriptions>
          </div>
          {/* <Modal
            centered
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            cancelText="Хаах"
            footer={null}
            closeIcon={<IoCloseOutline color="#fff" size={24} />}
          ></Modal> */}
          <Modal
            centered
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            // width={"100%"}
            cancelText="Хаах"
            footer={null}
            closeIcon={<IoCloseOutline color="#fff" size={24} />}
          >
            <iframe
              src={url}
              frameborder="0"
              id="pdf"
              width="100%"
              height="800px"
              title=" "
            ></iframe>
            {/* <iframe src={`${url}#toolbar=1`} frameborder="1"></iframe> */}
          </Modal>
          <Modal
            centered
            open={showOrder}
            onOk={() => setShowOrder(false)}
            onCancel={() => setShowOrder(false)}
            // width={"100%"}
            cancelText="Хаах"
            footer={null}
            closeIcon={<IoCloseOutline color="#fff" size={24} />}
          >
            <iframe
              src={url}
              frameborder="0"
              id="pdf"
              width="100%"
              height="800px"
              title=" "
            ></iframe>
            {/* <iframe src={`${url}#toolbar=1`} frameborder="1"></iframe> */}
          </Modal>
          <Drawer open={openDrawer} onClose={onClose} width={720}>
            <form onSubmit={onSubmit}>
              <div className="grid grid-cols-2 gap-x-10 gap-y-5">
                {selectedState === 4 ? (
                  <Field className="relative">
                    <Label className="text-sm/6 font-medium text-slate-700">
                      Тушаал
                    </Label>

                    <select
                      name="divisionId"
                      onChange={orderChoice}
                      className={cn(
                        "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                        " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                    >
                      <option value="" disabled selected hidden>
                        Тушаал сонгоно уу
                      </option>
                      {orders &&
                        orders.map((choice) => (
                          <option value={choice.id} key={choice.name}>
                            {choice.name}
                          </option>
                        ))}
                    </select>
                    <FaChevronDown
                      color="#475569"
                      className="group pointer-events-none absolute top-[52px] right-3 size-3 "
                    />
                  </Field>
                ) : (
                  ""
                )}
                <Field className="w-full relative">
                  <Label>Тайлбар</Label>
                  <Textarea onChange={onChange} className="inputStyle" />
                </Field>
              </div>

              <button
                type="submit"
                className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                Хадгалах
              </button>
            </form>
          </Drawer>
        </div>
        {/* <PDFViewer className="h-screen">
          <VerticalTableDocument data={detail} src={imgSrc} />
        </PDFViewer> */}
        <div
          style={{
            position: "absolute",
            top: "-1000px",
            left: "-1000px",
          }}
        >
          <AnketPdf data={detail} src={imgSrc} />
        </div>
      </div>
    </Layout>
  );
}
