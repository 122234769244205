import { Button, Form, message, Upload, DatePicker, Radio } from "antd";
import React, { useCallback } from "react";
import { CiCircleMinus } from "react-icons/ci";
import { Field, Fieldset, Input, Label } from "@headlessui/react";
import { cn } from "../../lib/utils";
import { FaChevronDown } from "react-icons/fa";
import {
  getAwardOrgs,
  getDivisions,
  getGenders,
  getEduTypes,
  UploadFile,
  getZahirgaa,
  getOccupations,
  getHolidayByOrg,
} from "../../services";
import { useEffect, useState } from "react";
import userprofile from "../../assets/user.svg";
import useFormDataStore from "../../stores/formDataStore";
import {
  checkByRegnum,
  getAwardCategory,
  getAwardsById,
} from "../../services/requests";
import { useAuth } from "../../context/AuthContext";
import dayjs from "dayjs";
import { FaAsterisk } from "react-icons/fa";
export default function RequestForm({ next }) {
  const { token } = useAuth();
  const [awards, setAwards] = useState();
  const [awardOrgs, setAwardOrgs] = useState();
  const [divisions, setDivisions] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [gender, setGender] = useState([]);
  const [eduTypes, setEduTypes] = useState([]);
  const [zahirgaa, setZahirgaa] = useState([]);
  const [awardCategory, setAwardCategory] = useState();
  const [current, setCurrent] = useState(true);

  const [info, setInfo] = useState({
    awardOrgId: null,
    awardId: null,
    lastName: "",
    firstName: "",
    birthDate: null,
    register: "",
    genderId: null,
    educationTypeId: null,
    holidayId: null,
    occupationId: null,
    divisionId: null,
    workDevisionYear: null,
    workYear: null,
    position: "",
    citizenshipId: null,
    picture: "",
    refCategoryId: null,
  });

  const [pastWorkPlace, setPastWorkplace] = useState([
    {
      workName: "",
      workPosition: "",
      startDate: null,
      endDate: null,
      isCurrent: null,
    },
  ]);
  const [history, setHistory] = useState([
    {
      awardName: "",
      giveDate: null,
    },
  ]);
  const { setInfoData, setAwardHistory, setWorkplace, infoData } =
    useFormDataStore();

  function fetchAwards(id) {
    getAwardsById({ id })
      .then((response) => {
        setAwards(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchZahirgaa() {
    getZahirgaa()
      .then((response) => {
        setZahirgaa(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchDivision() {
    getDivisions()
      .then((response) => {
        setDivisions(response);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchGenders() {
    getGenders()
      .then((response) => {
        if (response) {
          setGender(response);
          setLoading(false);
          console.log("gender", gender);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function fetchOccupations() {
    getOccupations()
      .then((response) => {
        setOccupations(response);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchOrgs() {
    getAwardOrgs()
      .then((response) => {
        console.log("responseORGS", response);
        setAwardOrgs(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchEduTypes() {
    getEduTypes()
      .then((response) => {
        setEduTypes(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function fetchHoliday(orgId) {
    getHolidayByOrg(orgId)
      .then((response) => {
        if (response.result) {
          console.log("responseeeeeeeeeeeeeeeeeee", response.data);
          setHolidays(response.data);
        }
      })
      .catch((error) => {});
  }
  function fetchAwardCategory(orgId) {
    getAwardCategory(orgId)
      .then((response) => {
        if (response.result) {
          console.log("responseeeeeeeeeeeeeeeeeee", response.data);
          setAwardCategory(response.data);
        }
      })
      .catch((error) => {});
  }
  useEffect(() => {
    fetchOrgs();
    // fetchAwards();
    fetchDivision();
    fetchGenders();
    fetchEduTypes();
    fetchZahirgaa();
    fetchOccupations();
  }, []);
  // let debounceTimer;

  // const debouncedCheckByRegnum = useCallback((parsedValue) => {
  //   clearTimeout(debounceTimer);
  //   debounceTimer = setTimeout(() => {
  //     checkByRegnum(parsedValue);
  //   }, 500); // Adjust the delay as needed
  // }, []);

  const handleInfoDataChange = (e) => {
    const { name, value, type } = e.target;
    const parsedValue = type === "number" ? parseFloat(value) || 0 : value;
    // if (name === "register") {
    //   // checkByRegnum(parsedValue);

    //   debouncedCheckByRegnum(value);
    //   setInfo((prev) => ({
    //     ...prev,
    //     register: parsedValue,
    //   }));
    // } else {
    setInfo((prev) => ({
      ...prev,
      [name]: parsedValue,
    }));
    // }
  };

  const handleSelectChange = (e, fieldName) => {
    const { value } = e.target;
    if (fieldName === "awardOrgId") {
      fetchHoliday(value);
      fetchAwardCategory(value);

      setInfo((prevState) => ({
        ...prevState,
        awardOrgId: value,
      }));
    } else if (fieldName === "refCategoryId") {
      fetchAwards(value);

      setInfo((prevState) => ({
        ...prevState,
        refCategoryId: value,
      }));
    } else {
      setInfo((prevState) => ({
        ...prevState,
        [fieldName]: isNaN(value) ? value : Number(value),
      }));
    }
  };

  const handleUploadChange = (info) => {
    const { status } = info.file;

    if (status === "done") {
      if (info.file.response && info.file.response.result === false) {
        message.error(info.file.response.message);
      } else {
        setInfo((prevData) => ({
          ...prevData,
          picture: {
            name: "picture",
            fileName: info.file.response.filename,
            type: info.file.response.mimetype,
            url: info.file.response.path,
            size: info.file.response.size,
          },
        }));
        message.success("Файл амжилттай хуулагдлаа");
      }
    } else if (status === "error") {
      message.error("Файл хуулахад алдаа гарлаа");
    }
  };

  const handleWorkChange = (index, e) => {
    const { name, value } = e.target;
    setPastWorkplace((prev) => {
      const updatedWorkplace = [...prev];
      updatedWorkplace[index] = {
        ...updatedWorkplace[index],
        [name]: value,
      };
      return updatedWorkplace;
    });
    if (name === "isCurrent") {
      setCurrent(value);
    }
  };

  const handleHistoryChange = (index, e) => {
    const { name, value } = e.target;
    setHistory((prev) => {
      const updatedHistory = [...prev];
      updatedHistory[index] = {
        ...updatedHistory[index],
        [name]: value,
      };
      return updatedHistory;
    });
  };

  const handleDateChange = (fieldName, index, date, dateString) => {
    if (fieldName === "birthDate") {
      setInfo((prev) => ({
        ...prev,
        birthDate: dateString,
      }));
      console.log("tursun _------------------------", dateString);
    } else if (fieldName === "giveDate") {
      setHistory((prev) => {
        const updatedHistory = [...prev];
        updatedHistory[index] = {
          ...updatedHistory[index],
          giveDate: dateString,
        };
        return updatedHistory;
      });
    } else {
      setPastWorkplace((prev) => {
        const updatedWorkplace = [...prev];
        updatedWorkplace[index] = {
          ...updatedWorkplace[index],
          [fieldName]: dateString,
        };
        return updatedWorkplace;
      });
    }
  };
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  const onSubmit = async () => {
    setInfoData(info);
    setAwardHistory(history);
    setWorkplace(pastWorkPlace);
    console.log(info);
    console.log(history);
    console.log(pastWorkPlace);

    next();
  };
  return (
    <>
      <Form onFinish={onSubmit}>
        <Fieldset className="gap-6 rounded-xl bg-white/5 ">
          {/* SHAGNAL SONGOH */}
          <div className="flex flex-col gap-4 pb-5 ">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <span className=" text-[#2C73EB] font-medium text-base">
                  ШАГНАЛ СОНГОХ:
                </span>
                <div className="border-b-2 border-[#2C73EB]"></div>
              </div>

              <div className="w-full grid grid-cols-4 gap-6">
                <Field>
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Харьяалагдах яам
                  </Label>
                  <div className="relative">
                    <select
                      required
                      name="awardOrgId"
                      className={cn(
                        "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                        " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                      onChange={(e) => handleSelectChange(e, "awardOrgId")}
                    >
                      <option value="" disabled selected hidden>
                        Харьяалагдах яам сонгоно уу
                      </option>
                      {awardOrgs &&
                        awardOrgs.map((awardOrg) => (
                          <option value={awardOrg.id} key={awardOrg.id}>
                            {awardOrg.name}
                          </option>
                        ))}
                    </select>
                    <FaChevronDown
                      color="#475569"
                      className=" group pointer-events-none absolute top-4 right-3 size-3 "
                    />
                  </div>
                </Field>
                <Field>
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Баяр тэмдэглэлт өдөр
                  </Label>
                  <div className="relative">
                    <select
                      required
                      className={cn(
                        "mt-3 text-[#475569] block w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                        " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                      name="holidayId"
                      onChange={(e) => handleSelectChange(e, "holidayId")}
                    >
                      <option value="" disabled selected hidden>
                        Баяр тэмдэглэлт өдөр сонгоно уу
                      </option>
                      {holidays &&
                        holidays.map((holiday) => (
                          <option value={holiday.id} key={holiday.id}>
                            {holiday.name}
                          </option>
                        ))}
                    </select>
                    <FaChevronDown
                      color="#475569"
                      className=" group pointer-events-none absolute top-4 right-3 size-3 "
                    />
                  </div>
                </Field>

                <Field>
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Шагналын төрөл
                  </Label>
                  <div className="relative">
                    <select
                      className={cn(
                        "mt-3 text-[#475569] block w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                        " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                      name="refCategoryId"
                      onChange={(e) => handleSelectChange(e, "refCategoryId")}
                    >
                      <option value="" disabled selected hidden>
                        Төрөл сонгоно уу
                      </option>
                      {awardCategory &&
                        awardCategory.map((award) => (
                          <option value={award.id} key={award.id}>
                            {award.name}
                          </option>
                        ))}
                    </select>
                    <FaChevronDown
                      color="#475569"
                      className=" group pointer-events-none absolute top-4 right-3 size-3 "
                    />
                  </div>
                </Field>
                <Field>
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Шагналын нэр
                  </Label>
                  <div className="relative">
                    <select
                      required
                      className={cn(
                        "mt-3 text-[#475569] block w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                        " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                      )}
                      name="awardId"
                      onChange={(e) => handleSelectChange(e, "awardId")}
                    >
                      <option value="" disabled selected hidden>
                        Шагнал сонгоно уу
                      </option>
                      {awards &&
                        awards.map((award) => (
                          <option value={award.id} key={award.id}>
                            {award.name}
                          </option>
                        ))}
                    </select>
                    <FaChevronDown
                      color="#475569"
                      className=" group pointer-events-none absolute top-4 right-3 size-3 "
                    />
                  </div>
                </Field>
              </div>
            </div>
          </div>
          {/* HUVIIN MEDEELEL */}
          <div className="flex flex-col gap-4 pb-5">
            <div className="flex flex-col gap-2">
              <span className=" text-[#2C73EB] font-medium text-base ">
                ХУВИЙН МЭДЭЭЛЭЛ:
              </span>
              <div className="border-b-2 border-[#2C73EB]"></div>
            </div>
            <div className="w-full flex justify-between gap-11">
              <Upload
                className=" border bg-white/5 rounded-lg h-5/6 py-2 px-3 mt-10"
                action={UploadFile()}
                headers={{
                  Authorization: `Bearer ${token}`,
                }}
                name="file"
                onChange={handleUploadChange}
              >
                <div className="flex flex-col items-center gap-4 pb-2">
                  <img src={userprofile} alt="aaa" />
                  <span className="text-[13px] font-normal leading-5 text-slate-500 text-center flex">
                    Энд дарж хуулна уу <FaAsterisk color="red" size={5} />
                  </span>
                </div>
              </Upload>

              <div className="w-full  grid grid-cols-3 gap-6">
                <Field
                  className="w-10/12"
                  data-error="Та энэ талбар-г бөглөнө үү"
                >
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Эцэг эхийн нэр
                  </Label>

                  <Input
                    name="lastName"
                    required
                    data-error="Та энэ талбар-г бөглөнө үү"
                    onChange={handleInfoDataChange}
                    placeholder="Утга бичнэ үү"
                    className="inputStyle "
                  />
                </Field>
                <Field className="w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Өөрийн нэр
                  </Label>

                  <Input
                    name="firstName"
                    placeholder="Утга бичнэ үү"
                    value={infoData.firstName}
                    type="text"
                    required={true}
                    className="inputStyle"
                    onChange={handleInfoDataChange}
                  />
                </Field>

                <Field className="w-10/12 flex flex-col">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Төрсөн огноо
                  </Label>

                  <DatePicker
                    placeholder="Огноо сонгоно уу"
                    onChange={(date, dateString, index) =>
                      handleDateChange("birthDate", index, date, dateString)
                    }
                    disabledDate={disabledDate}
                    // format="YYYY-MM-DD"
                    required
                    inputReadOnly={true}
                    className="inputStyle"
                  />
                </Field>
                <Field className="w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Регистрийн дугаар (АБ12345678)
                  </Label>

                  <Input
                    placeholder="Утга бичнэ үү"
                    name="register"
                    pattern="[А-ЯӨҮа-яөү]{2}[0-9]{8}"
                    required={true}
                    // pattern="[A-Za-z]{2}[0-9]{8}"
                    className="inputStyle"
                    onChange={handleInfoDataChange}
                    minLength={10}
                  />
                </Field>
                <Field className="relative w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Хүйс
                  </Label>
                  <select
                    name="genderId"
                    required
                    className={cn(
                      "mt-3 block text-[#475569] w-full relative appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    onChange={(e) => handleSelectChange(e, "genderId")}
                  >
                    <option value="" disabled selected hidden>
                      Хүйс сонгоно уу
                    </option>
                    {gender &&
                      gender.data &&
                      gender.data.map((choice) => (
                        <option value={choice.id} key={choice.name}>
                          {choice.name}
                        </option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-[52px] right-3 size-3 "
                  />
                </Field>
                <Field className="relative w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Боловсрол
                  </Label>
                  <select
                    name="educationTypeId"
                    required
                    className={cn(
                      "mt-3 block text-[#475569] w-full relative appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    onChange={(e) => handleSelectChange(e, "educationTypeId")}
                  >
                    <option value="" disabled selected hidden>
                      Боловсролын түвшин сонгоно уу
                    </option>
                    {eduTypes &&
                      eduTypes.map((choice) => (
                        <option value={choice.id} key={choice.id}>
                          {choice.name}
                        </option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-[52px] right-3 size-3 "
                  />
                </Field>
                <Field className="relative w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Мэргэжил
                  </Label>
                  <select
                    required
                    name="occupationId"
                    onChange={(e) => handleSelectChange(e, "occupationId")}
                    className={cn(
                      "mt-3 block text-[#475569] w-full relative appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                  >
                    <option value="" disabled selected hidden>
                      Мэргэжил сонгоно уу
                    </option>
                    {occupations &&
                      occupations.data &&
                      occupations.data.map((choice) => (
                        <option value={choice.id} key={choice.name}>
                          {choice.name}
                        </option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-[52px] right-3 size-3 "
                  />

                  {/* <Input
                    placeholder="Утга бичнэ үү"
                    name="occupation"
                    className="inputStyle"
                    onChange={handleInfoDataChange}
                  /> */}
                </Field>
                <Field className="relative w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Үндсэн захиргаа
                  </Label>
                  <select
                    required
                    name="citizenshipId"
                    className={cn(
                      "mt-3 block text-[#475569] w-full relative appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    onChange={(e) => handleSelectChange(e, "citizenshipId")}
                  >
                    <option value="" disabled selected hidden>
                      Захиргаа сонгоно уу
                    </option>
                    {zahirgaa &&
                      zahirgaa.map((choice) => (
                        <option value={choice.id} key={choice.name}>
                          {choice.name}
                        </option>
                      ))}
                  </select>

                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-[52px] right-3 size-3"
                  />
                </Field>
                <Field className="relative w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Ажилладаг салбар
                  </Label>
                  <select
                    required
                    name="divisionId"
                    className={cn(
                      "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    onChange={(e) => handleSelectChange(e, "divisionId")}
                  >
                    <option value="" disabled selected hidden>
                      Ажилладаг салбар сонгоно уу
                    </option>
                    {divisions &&
                      divisions.data &&
                      divisions.data.map((choice) => (
                        <option value={choice.id} key={choice.name}>
                          {choice.name}
                        </option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-[52px] right-3 size-3 "
                  />
                </Field>
                <Field className="w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Салбартаа ажилласан жил
                  </Label>
                  <Input
                    placeholder="Утга бичнэ үү"
                    type="number"
                    name="workDevisionYear"
                    min={0}
                    max={100}
                    required
                    className="inputStyle"
                    onChange={handleInfoDataChange}
                  />
                </Field>
                <Field className="w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Улсад нийт ажилласан жил
                  </Label>
                  <Input
                    placeholder="Утга бичнэ үү"
                    type="number"
                    required
                    name="workYear"
                    className="inputStyle"
                    min={0}
                    max={100}
                    onChange={handleInfoDataChange}
                  />
                </Field>
                <Field className="w-10/12">
                  <Label className="text-sm/6 font-medium text-slate-700">
                    Одоо хашиж буй албан тушаал
                  </Label>
                  <Input
                    placeholder="Утга бичнэ үү"
                    name="position"
                    required
                    className="inputStyle"
                    onChange={handleInfoDataChange}
                  />
                </Field>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4 pb-5">
            <div className="flex flex-col gap-2">
              <span className=" text-[#2C73EB] font-medium text-base ">
                УРЬД БОЛОН ОДОО АЖИЛЛАЖ БАЙГАА ГАЗАР:
              </span>
              <div className="border-b-2 border-[#2C73EB]"></div>
            </div>
            <Fieldset>
              <Form.List name="workplace" className="">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          alignItems: "end",
                          gap: 30,
                        }}
                      >
                        <Form.Item {...restField} className="w-full">
                          <Label className="text-sm/6 font-medium text-slate-700">
                            Ажлын газрын нэр
                          </Label>
                          <Input
                            name="workName"
                            placeholder="Утга бичнэ үү"
                            className={"inputStyle"}
                            onChange={(e) => handleWorkChange(index, e)}
                          />
                        </Form.Item>
                        <Form.Item {...restField} className="w-full">
                          <Label className="text-sm/6 font-medium text-slate-700">
                            Эрхэлсэн ажил
                          </Label>
                          <Input
                            onChange={(e) => handleWorkChange(index, e)}
                            name="workPosition"
                            placeholder="Утга бичнэ үү"
                            className="inputStyle"
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          className="w-full  flex flex-col gap-10"
                        >
                          <Label className="text-sm/6 font-medium text-slate-700">
                            Одоо ажиллаж байгаа эсэх
                          </Label>
                          <Radio.Group
                            className="mt-3 block w-full rounded-lg   py-1.5 px-3 text-sm/6"
                            name="isCurrent"
                            onChange={(e) => handleWorkChange(index, e)}
                          >
                            <Radio value={true}>Тийм</Radio>
                            <Radio value={false}>Үгүй</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item {...restField} className="w-full">
                          <Label className="text-sm/6 font-medium text-slate-700">
                            Ажиллаж эхэлсэн огноо
                          </Label>
                          <DatePicker
                            placeholder="Огноо сонгоно уу"
                            onChange={(date, dateString) =>
                              handleDateChange(
                                "startDate",
                                index,
                                date,
                                dateString
                              )
                            }
                            className="inputStyle"
                            format="YYYY-MM-DD"
                          />
                        </Form.Item>
                        {!current && (
                          <Form.Item
                            {...restField}
                            name="endDate"
                            className="w-full"
                          >
                            <Label className="text-sm/6 font-medium text-slate-700">
                              Ажлаас гарсан огноо
                            </Label>
                            <DatePicker
                              placeholder="Огноо сонгоно уу"
                              onChange={(date, dateString) =>
                                handleDateChange(
                                  "endDate",
                                  index,
                                  date,
                                  dateString
                                )
                              }
                              format="YYYY-MM-DD"
                              className="inputStyle"
                            />
                          </Form.Item>
                        )}
                        <CiCircleMinus onClick={() => remove(name)} size={70} />
                      </div>
                    ))}
                    <Form.Item className="w-[10%]">
                      <Button
                        onClick={() => add()}
                        block
                        className="border  border-[#166534] bg-[#F0FDF4] rounded-lg text-sm font-medium leading-6 text-green-900"
                      >
                        Мөр нэмэх
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Fieldset>
          </div>
          <div className="flex flex-col gap-4 pb-5">
            <div className="flex flex-col gap-2">
              <span className=" text-[#2C73EB] font-medium text-base ">
                ТӨР, ЗАСГИЙН БОЛОН БУСАД ЯМАР ШАГНАЛААР ХЭЗЭЭ ШАГНАГДСАН:
              </span>
              <div className="border-b-2 border-[#2C73EB]"></div>
            </div>
            <Fieldset>
              <Form.List name="awardHistory" className="">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                          alignItems: "center",
                          gap: 40,
                        }}
                      >
                        <Form.Item {...restField} className="w-full">
                          <Label className="text-sm/6 font-medium text-slate-700">
                            Шагналын нэр
                          </Label>
                          <Input
                            placeholder="Утга бичнэ үү"
                            className="inputStyle"
                            name="awardName"
                            onChange={(e) => handleHistoryChange(index, e)}
                          />
                        </Form.Item>
                        <Form.Item {...restField} className="w-4/12">
                          <Label className="text-sm/6 font-medium text-slate-700">
                            Шагнагдсан огноо
                          </Label>

                          <DatePicker
                            placeholder="Огноо сонгоно уу"
                            onChange={(date, dateString) =>
                              handleDateChange(
                                "giveDate",
                                index,
                                date,
                                dateString
                              )
                            }
                            className="inputStyle"
                            format="YYYY-MM-DD"
                          />
                        </Form.Item>

                        <CiCircleMinus
                          onClick={() => remove(name)}
                          size={30}
                          color="red"
                        />
                      </div>
                    ))}
                    <Form.Item className="w-[10%]">
                      <Button
                        onClick={() => add()}
                        block
                        className="border  border-[#166534] bg-[#F0FDF4] rounded-lg text-sm font-medium leading-6 text-green-900"
                      >
                        Мөр нэмэх
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Fieldset>
          </div>

          <div className="flex justify-end gap-5">
            <button
              type="submit"
              className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
            >
              Дараагийн алхам
            </button>
          </div>
        </Fieldset>
      </Form>
    </>
  );
}
