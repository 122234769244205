import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import {
  addUser,
  DeleteUser,
  getOrganizations,
  getSingleUser,
  getUserList,
  UpdatePassword,
  updateUser,
} from "../services";
import { getSubOrgsById } from "../services/suborgs";
import Header from "../components/Header";
import { Field, Input, Label } from "@headlessui/react";
import { cn } from "../lib/utils";
import plus from "../assets/plus-sign.svg";
import { ConfigProvider, Table, Drawer, message } from "antd";
import { FaChevronDown } from "react-icons/fa";
import { getRoles } from "../services/Auth";
import { MdDelete, MdOutlineEdit } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";

export default function UsersList() {
  const [users, setUsers] = useState();
  const [orgs, setOrgs] = useState();
  const [subOrgs, setSubOrgs] = useState();
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [roles, setRoles] = useState([]);
  const [editId, setEditId] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [userInfo, setUserInfo] = useState({
    lastName: "",
    firstName: "",
    registerNumber: "",
    orgId: "",
    roleId: "",
    subOrgId: "",
    refRole: "",
    email: "",
    phone: "",
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    registerNumber: "",
    orgId: null,
    subOrgId: null,
    roleId: "",
    email: "",
    phone: "",
  });

  async function fetchUserList() {
    const response = await getUserList();
    setUsers(response.data);
    console.log("usersss", response);
  }

  const handleSelectChange = (e, fieldName) => {
    const { value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: isNaN(value) ? value : Number(value),
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleEditSelectChange = (e, fieldName) => {
    const { value } = e.target;

    setUserInfo((prevState) => ({
      ...prevState,
      [fieldName]: isNaN(value) ? value : Number(value),
    }));
  };
  function fetchOrgs() {
    getOrganizations()
      .then((response) => {
        setOrgs(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function fetchRoles() {
    getRoles()
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchOrgs();
    fetchUserList();
    fetchRoles();
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const fetchUserData = async (id) => {
    setEditId(id);
    getSingleUser(id)
      .then((response) => {
        console.log(response);
        // setUserInfo(response);
        const user = response;
        setUserInfo({
          lastName: user.lastName || "",
          firstName: user.firstName || "",
          registerNumber: user.registerNumber || "",
          orgId: user.orgId || "",
          roleId: user.subOrgId || "",
          subOrgId: user.subOrganization?.id || "",
          refRole: user.refRole.name || "",
          email: user.email || "",
          phone: user.phone || "",
        });

        setOpenEdit(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const removeUser = async (id) => {
    try {
      const response = await DeleteUser(id);
      if (response.statusCode === 200) {
        setUsers((prevData) => prevData.filter((item) => item.id !== id));
        console.log("Successfully deleted");
        message.success("Амжилттай устгагдлаа");
      } else {
        message.error("Устгахад алдаа гарлаа");
      }
    } catch (error) {
      message.error("Алдаа гарлаа. Та дахин оролдоно уу.");
    }
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
    updateUser(editId, userInfo)
      .then((response) => {
        if (response.result) {
          message.success(response.message);
          setOpen(false);
        } else {
          message.error(response.error);
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    addUser(formData)
      .then((response) => {
        if (response.result) {
          message.success(response.message);
          setOpen(false);
        } else {
          message.error(response.error);
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error);
      });
  };

  const findAwards = async (e) => {
    const { value } = e.target;
    const response = await getSubOrgsById(value);
    setSubOrgs(response.data);
    setFormData((prevState) => ({
      ...prevState,
      orgId: Number(value),
    }));
  };
  const findEditAwards = async (e) => {
    const { value } = e.target;
    const response = await getSubOrgsById(value);
    setSubOrgs(response.data);
    setUserInfo((prevState) => ({
      ...prevState,
      orgId: Number(value),
    }));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Нэр",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Регистр",
      dataIndex: "registerNumber",
      key: "registerNumber",
    },
    {
      title: "Цахим шуудан",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Үйлдэл",
      key: "action",
      dataIndex: "action",
      render: (_, record) => (
        <div style={{ position: "relative", display: "inline-block" }}>
          <div className="flex gap-2 items-center justify-between">
            <button onClick={() => fetchUserData(record.id)}>
              <MdOutlineEdit size={25} />
            </button>
            <button onClick={() => removeUser(record.id)}>
              <MdDelete size={25} />
            </button>

            <button onClick={() => UpdatePassword(record.email)}>
              <RiLockPasswordFill size={25} />
            </button>
          </div>
        </div>
      ),
    },
  ];
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const showTotal = (total) => (
    <div className="font-semibold">Нийт - {total}</div>
  );
  return (
    <Layout>
      <Header title="Хэрэглэгчийн жагсаалт" />
      <div className="p-6 flex flex-col gap-6">
        <span className="flex justify-end items-center">
          <button
            to="/requests/create"
            className="pl-4 flex items-center gap-[10px] pr-5 py-2 text-white bg-[#2C73EB] rounded-xl  text-center"
            onClick={showDrawer}
          >
            <img src={plus} alt="" />
            <span className="text-sm font-bold">Шинэ бүртгэл</span>
          </button>
        </span>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Golos text",
              colorText: "#334155",
              fontWeightStrong: 600,
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={users}
            scroll={{ x: "100%" }}
            rowKey="id"
            pagination={{
              showTotal: showTotal,
              current: currentPage,
              pageSize: pageSize,
              onChange: handlePageChange,
              showSizeChanger: true,
              position: ["bottomLeft"],
              locale: { items_per_page: "" },
              pageSizeOptions: ["5", "10", "20", "50"],
              onShowSizeChange: (current, size) => setPageSize(size),
            }}
          />
        </ConfigProvider>
        <Drawer
          title="Хэрэглэгч бүртгэх"
          open={open}
          onClose={onClose}
          width={720}
        >
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div className="grid grid-cols-2">
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Эцэг эхийн нэр
                </Label>
                <Input
                  name="lastName"
                  placeholder="Утга бичнэ үү"
                  className="inputStyle "
                  onChange={handleChange}
                />
              </Field>
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Өөрийн нэр
                </Label>
                <Input
                  name="firstName"
                  placeholder="Утга бичнэ үү"
                  className="inputStyle "
                  onChange={handleChange}
                />
              </Field>
            </div>
            <div className="grid grid-cols-2">
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Регистрийн дугаар
                </Label>
                <Input
                  name="registerNumber"
                  placeholder="Утга бичнэ үү"
                  className="inputStyle "
                  maxLength={10}
                  onChange={handleChange}
                />
              </Field>
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Дээд байгууллага сонгоно уу
                </Label>
                <div className="relative">
                  <select
                    name="orgId"
                    className={cn(
                      "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    onChange={findAwards}
                  >
                    <option value="" disabled selected hidden>
                      Дээд байгууллага сонгоно уу
                    </option>
                    {orgs &&
                      orgs.map((org) => (
                        <option value={org.id} key={org.id}>
                          {org.name}
                        </option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-4 right-3 size-3 "
                  />
                </div>
              </Field>
            </div>
            <div className="grid grid-cols-2">
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Хэрэглэгчийн эрх
                </Label>
                <div className="relative">
                  <select
                    onChange={(e) => handleSelectChange(e, "roleId")}
                    name="roleId"
                    className={cn(
                      "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    // onChange={subOrgs}
                  >
                    <option value="" disabled selected hidden>
                      Хэрэглэгчийн эрх сонгоно уу
                    </option>
                    {/* <option value="">Admin</option> */}

                    {roles &&
                      roles.map((item) => (
                        <option value={item.id}>{item.position}</option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-4 right-3 size-3 "
                  />
                </div>
              </Field>
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Салбар байгууллага сонгоно уу
                </Label>
                <div className="relative">
                  <select
                    name="subOrgId"
                    className={cn(
                      "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    onChange={(e) => handleSelectChange(e, "subOrgId")}
                  >
                    <option value="" disabled selected hidden>
                      Салбар байгууллага сонгоно уу
                    </option>
                    {subOrgs &&
                      subOrgs.map((subOrg) => (
                        <option value={subOrg.id} key={subOrg.id}>
                          {subOrg.name}
                        </option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-4 right-3 size-3 "
                  />
                </div>
              </Field>
            </div>
            <div className="grid grid-cols-2">
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Цахим шуудан
                </Label>
                <Input
                  name="email"
                  required
                  placeholder="Утга бичнэ үү"
                  className="inputStyle "
                  onChange={handleChange}
                />
              </Field>
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Утасны дугаар
                </Label>
                <Input
                  name="phone"
                  placeholder="Утга бичнэ үү"
                  required
                  type="number"
                  className="inputStyle "
                  onChange={handleChange}
                />
              </Field>
            </div>
            <div className="flex justify-end mr-14">
              <button
                type="submit"
                className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                Бүртгэх
              </button>
            </div>
          </form>
        </Drawer>
        <Drawer
          title="Хэрэглэгчийн мэдээлэл засах"
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          width={720}
        >
          <form onSubmit={handleEditSubmit} className="flex flex-col gap-4">
            <div className="grid grid-cols-2">
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Эцэг эхийн нэр
                </Label>
                <Input
                  name="lastName"
                  placeholder="Утга бичнэ үү"
                  value={userInfo?.lastName || ""}
                  className="inputStyle"
                  onChange={handleEditChange}
                />
              </Field>
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Өөрийн нэр
                </Label>
                <Input
                  name="firstName"
                  placeholder="Утга бичнэ үү"
                  className="inputStyle "
                  value={userInfo?.firstName || ""}
                  onChange={handleEditChange}
                />
              </Field>
            </div>
            <div className="grid grid-cols-2">
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Регистрийн дугаар
                </Label>
                <Input
                  name="registerNumber"
                  value={userInfo?.registerNumber || ""}
                  placeholder="Утга бичнэ үү"
                  className="inputStyle "
                  maxLength={10}
                  onChange={handleEditChange}
                />
              </Field>
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Дээд байгууллага сонгоно уу
                </Label>
                <div className="relative">
                  <select
                    name="orgId"
                    value={userInfo?.orgId || ""}
                    className={cn(
                      "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    onChange={findEditAwards}
                  >
                    {/* <option value="" disabled selected hidden>
                      Дээд байгууллага сонгоно уу
                    </option> */}
                    {orgs &&
                      orgs.map((org) => (
                        <option value={org.id} key={org.id}>
                          {org.name}
                        </option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-4 right-3 size-3 "
                  />
                </div>
              </Field>
            </div>
            <div className="grid grid-cols-2">
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Хэрэглэгчийн эрх
                </Label>
                <div className="relative">
                  <select
                    onChange={(e) => handleEditSelectChange(e, "roleId")}
                    value={userInfo?.refRole || ""}
                    name="roleId"
                    className={cn(
                      "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6 ",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    // onChange={subOrgs}
                  >
                    {/* <option value="" disabled selected hidden>
                      Хэрэглэгчийн эрх сонгоно уу
                    </option> */}
                    {/* <option value="">Admin</option> */}

                    {roles &&
                      roles.map((item) => (
                        <option value={item.id}>{item.position}</option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-4 right-3 size-3 "
                  />
                </div>
              </Field>
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Салбар байгууллага сонгоно уу
                </Label>
                <div className="relative">
                  <select
                    name="subOrgId"
                    value={userInfo?.subOrgId || ""}
                    className={cn(
                      "mt-3 block text-[#475569] w-full appearance-none rounded-lg border bg-white/5 py-2 px-3 text-sm/6",
                      " data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                    onChange={(e) => handleEditSelectChange(e, "subOrgId")}
                  >
                    {subOrgs &&
                      subOrgs.map((subOrg) => (
                        <option value={subOrg.id} key={subOrg.id}>
                          {subOrg.name}
                        </option>
                      ))}
                  </select>
                  <FaChevronDown
                    color="#475569"
                    className=" group pointer-events-none absolute top-4 right-3 size-3 "
                  />
                </div>
              </Field>
            </div>
            <div className="grid grid-cols-2">
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Цахим шуудан
                </Label>
                <Input
                  name="email"
                  value={userInfo?.email || ""}
                  required
                  placeholder="Утга бичнэ үү"
                  className="inputStyle "
                  onChange={handleEditChange}
                />
              </Field>
              <Field className="w-10/12">
                <Label className="text-sm/6 font-medium text-slate-700">
                  Утасны дугаар
                </Label>
                <Input
                  name="phone"
                  placeholder="Утга бичнэ үү"
                  required
                  value={userInfo?.phone || ""}
                  type="number"
                  className="inputStyle "
                  onChange={handleEditChange}
                />
              </Field>
            </div>
            <div className="flex justify-end mr-14">
              <button
                type="submit"
                className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                Бүртгэх
              </button>
            </div>
          </form>
        </Drawer>
      </div>
    </Layout>
  );
}
