import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import {
  ConfigProvider,
  message,
  Table,
  DatePicker,
  Select,
  Button,
  Input,
} from "antd";
import { Link } from "react-router-dom";
import { deleteSingleArchive, getArchives } from "../services/archives";
import moment from "moment";
import plus from "../assets/plus-sign.svg";
import { MdDelete } from "react-icons/md";
import { PiDotsThreeCircleFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { getAwards } from "../services/awards";
import { useAuth } from "../context/AuthContext";
import { FaSearch } from "react-icons/fa";

const { Option } = Select;

export default function Archive() {
  const { user } = useAuth();
  const [archives, setArchives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [awards, setAwards] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedAward, setSelectedAward] = useState();
  const [selectedDate, setSelectedDate] = useState(null);

  const navigate = useNavigate();
  const toggleMenu = () => {
    setShowMore(!showMore);
  };

  function fetchAwards() {
    getAwards()
      .then((response) => {
        setAwards(response.data);
      })
      .catch((error) => {});
  }

  const removeArchive = async (id) => {
    try {
      const response = await deleteSingleArchive(id);
      if (response.result) {
        setArchives((prevData) => prevData.filter((item) => item.id !== id));
        console.log("Successfully deleted");
        message.success("Амжилттай устгагдлаа");
      } else {
        message.error("Устгахад алдаа гарлаа");
      }
    } catch (error) {
      message.error("Алдаа гарлаа. Та дахин оролдоно уу.");
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Овог",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Өөрийн нэр",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Регистрийн дугаар",
      dataIndex: "register",
      key: "register",
    },
    {
      title: "Харьяалагдах яам",
      key: "awardOrg",
      dataIndex: "awardOrg",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Тушаалын дугаар",
      key: "tushaalName",
      dataIndex: "tushaalName",
    },
    {
      title: "Тушаалын огноо",
      key: "tushaalDate",
      dataIndex: "tushaalDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Шагнал",
      key: "awardTable",
      dataIndex: "awardTable",
      render: (_, record) => record.awardTable,
    },
    {
      title: "Бүртгэсэн огноо",
      key: "createdDate",
      dataIndex: "createdDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Төлөв",
      key: "awardState",
      dataIndex: "awardState",
      render: (_, record) => (
        <div className=" py-1 px-3 w-[100px] bg-green-500 m-0 text-center text-white rounded-xl font-semibold text-[12px]">
          {record.awardState}
        </div>
      ),
    },
    {
      title: "Үйлдэл",
      key: "action",
      dataIndex: "action",
      render: (_, record) => (
        <div style={{ position: "relative", display: "inline-block" }}>
          {!showMore && (
            <button
              onClick={toggleMenu}
              className="font-bold transition-all duration-300 ease-in"
            >
              <PiDotsThreeCircleFill size={25} color="black" fill="#ccc" />
            </button>
          )}

          {showMore && (
            <div className="flex gap-2 items-center justify-between">
              {/* <button onClick={() => navigate(`/archive/edit/${record.id}`)}>
                <MdOutlineEdit size={25} />
              </button> */}
              <button onClick={() => removeArchive(record.id)}>
                <MdDelete size={25} fill="" color="red" />
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchAwards();
    getArchives()
      .then((response) => {
        if (response.result) {
          setArchives(response.data);
          setFilteredDataSource(response.data);
          setLoading(false);
          console.log("response--------------", response.data);
        }
        console.log("response+++++++++++++++++", response);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error.message", error.message);
      });
  }, []);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const showTotal = (total) => (
    <div className="font-semibold">Нийт - {total}</div>
  );

  const dataSource =
    filteredDataSource &&
    filteredDataSource.map((_, i) => ({
      key: _?.id,
      id: i + 1,
      firstName: _?.firstName,
      lastName: _?.lastName,
      register: _?.register,
      awardOrg: _?.awardOrg?.name,
      tushaalName: _?.tushaalName,
      tushaalDate: _?.tushaalDate,
      awardTable: _?.awardTable?.name,
      awardState: _?.awardState?.stateName,
      createdDate: _?.createdDate,
    }));

  const handleSearch = () => {
    let filteredData = archives;

    if (searchText) {
      const searchTextLower = searchText.toLowerCase();
      filteredData = filteredData.filter(
        (item) =>
          item.firstName?.toLowerCase().includes(searchTextLower) ||
          item.lastName?.toLowerCase().includes(searchTextLower) ||
          item.awardOrg?.name?.toLowerCase().includes(searchTextLower) ||
          item.register?.toLowerCase().includes(searchTextLower)
      );
    }

    if (selectedAward) {
      filteredData = filteredData.filter(
        (item) => item.awardTable?.name === selectedAward
      );
    }
    if (selectedDate) {
      const selectedTime = new Date(selectedDate).setHours(0, 0, 0, 0);
      filteredData = filteredData.filter((item) => {
        const itemTime = new Date(item.createdDate).setHours(0, 0, 0, 0);
        return itemTime === selectedTime;
      });
    }
    setFilteredDataSource(filteredData);
  };

  return (
    <Layout>
      <Header title="Архив" count={archives.total} />
      <div className="p-6 flex flex-col gap-6">
        <span className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <Input
              placeholder="Овог,нэр,регистрийн дугаараар хайх"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={{ width: 300 }}
            />
            <Select
              placeholder="Шагнал сонгох"
              value={selectedAward || undefined}
              onChange={(value) => setSelectedAward(value)}
              style={{ width: 200 }}
              allowClear
            >
              {awards &&
                awards.map((award) => (
                  <Option key={award.id} value={award.name}>
                    {award.name}
                  </Option>
                ))}
            </Select>
            <DatePicker
              placeholder="Огноо сонгох"
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              style={{ width: 200 }}
              allowClear
            />
            <Button type="primary" onClick={handleSearch}>
              <FaSearch color="white" size={16} />
            </Button>
          </div>
          {user?.refRole?.name === "chiefman" ? (
            ""
          ) : (
            <Link
              to="/archive/create"
              className="pl-4 flex items-center gap-[10px] pr-5 py-2 text-white bg-[#2C73EB] rounded-xl  text-center"
            >
              <img src={plus} alt="" />
              <span className="text-sm font-bold">Шинэ бүртгэл</span>
            </Link>
          )}
        </span>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "Golos text",
              colorText: "#334155",
              fontWeightStrong: 600,
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={dataSource && dataSource}
            scroll={{ x: "100%" }}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              onChange: handlePageChange,
              showSizeChanger: true,
              position: ["bottomRight"],
              locale: { items_per_page: "" },
              pageSizeOptions: ["5", "10", "20", "50"],
              onShowSizeChange: (current, size) => setPageSize(size),
              showTotal: showTotal,
            }}
          />
        </ConfigProvider>
      </div>
    </Layout>
  );
}
