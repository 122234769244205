import React, { useEffect, useState } from "react";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { changeStatesByBulk, getReviewedRequests } from "../services/requests";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Drawer,
  Input,
  message,
  Table,
} from "antd";
import moment from "moment";
import { PiDotsThreeCircleFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { IoIosEye } from "react-icons/io";
import { Label, Textarea, Field } from "@headlessui/react";
import { FaSearch } from "react-icons/fa";

export default function ReviewedList() {
  const [requests, setRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [list, setList] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showMore, setShowMore] = useState();
  const [searchText, setSearchText] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedAward, setSelectedAward] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [data, setData] = useState({
    comment: "",
    stateId: 7,
    archList: null,
  });
  const navigate = useNavigate();
  const toggleMenu = () => {
    setShowMore(!showMore);
  };

  function fetchReviewed() {
    getReviewedRequests()
      .then((response) => {
        if (response.result) {
          setList(response.data);
          setFilteredDataSource(response.data);
          console.log(response.data, "reviewed");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    fetchReviewed();
  }, []);

  const onClose = () => {
    setOpenDrawer(false);
  };
  const onSelectChange = (newSelectedRowKeys, selected) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    console.log("selectedRowKeys selected: ", selected);
    setSelectedRowKeys(newSelectedRowKeys);
    setData((prev) => ({
      ...prev,
      archList: newSelectedRowKeys,
    }));
    console.log("data: ", data);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChange = (e) => {
    const { value } = e.target;
    setData((prev) => ({
      ...prev,
      comment: value,
    }));
  };
  const statusColors = {
    Хянасан: "bg-[#2c73eb] text-white",
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (_, record) => record?.key + 1,
    },
    {
      title: "Овог",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Нэр",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Шагнал",
      key: "awardTable",
      dataIndex: "awardTable",
      render: (_, record) => record.awardTable,
    },
    {
      title: "Илгээсэн байгууллага",
      key: "subOrganization",
      dataIndex: "subOrganization",
      render: (_, record) => record.subOrganization,
    },
    {
      title: "Төлөв",
      key: "awardState",
      dataIndex: "awardState",
      render: (_, record) => (
        <div
          className={`py-1 px-3 w-[100px] ${
            statusColors[record.awardState] || " "
          }
           m-0 text-center text-white rounded-xl font-semibold text-[12px]`}
        >
          {record.awardState}
        </div>
      ),
    },

    {
      title: "Илгээсэн",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Үйлдэл",
      key: "action",
      dataIndex: "action",
      render: (_, record) => (
        <div style={{ position: "relative", display: "inline-block" }}>
          {!showMore && (
            <button
              onClick={toggleMenu}
              className="font-bold transition-all duration-300 ease-in"
            >
              <PiDotsThreeCircleFill size={25} color="black" fill="#ccc" />
            </button>
          )}

          {showMore && (
            <div className="flex gap-2 items-center justify-between">
              <button onClick={() => navigate(`/request/detail/${record.id}`)}>
                <IoIosEye size={25} />
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];
  const dataSource = filteredDataSource.map((_, i) => ({
    key: i,
    id: _?.id,
    firstName: _.firstName,
    lastName: _.lastName,
    awardTable: _.awardTable?.name,
    subOrganization: _.subOrganization?.name,
    awardState: _?.awardState?.stateName,
    createdDate: _?.createdDate,
  }));
  const handleSearch = () => {
    let filteredData = requests;

    if (searchText) {
      const searchTextLower = searchText.toLowerCase();
      filteredData = filteredData.filter(
        (item) =>
          item.firstName?.toLowerCase().includes(searchTextLower) ||
          item.lastName?.toLowerCase().includes(searchTextLower) ||
          item.awardOrg?.name?.toLowerCase().includes(searchTextLower) ||
          item.register?.toLowerCase().includes(searchTextLower)
      );
    }

    if (selectedAward) {
      filteredData = filteredData.filter(
        (item) => item.awardTable?.name === selectedAward
      );
    }
    if (selectedDate) {
      const selectedTime = new Date(selectedDate).setHours(0, 0, 0, 0);
      filteredData = filteredData.filter((item) => {
        const itemTime = new Date(item.createdDate).setHours(0, 0, 0, 0);
        return itemTime === selectedTime;
      });
    }
    setFilteredDataSource(filteredData);
  };

  function onClick() {
    console.log(selectedRowKeys);
    setOpenDrawer(true);
  }
  function onSubmit(e) {
    e.preventDefault();
    changeStatesByBulk(data).then((response) => {
      if (response.result) {
        message.success(response.message);
        setOpenDrawer(false);
        fetchReviewed();
      } else {
        message.error(response.message);
      }
    });
  }
  const showTotal = (total) => (
    <div className="font-semibold">Нийт - {total}</div>
  );
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  return (
    <Layout>
      <Header title="Хянасан хүсэлтийн жагсаалт" />
      <div className="p-6">
        <div className="flex flex-col gap-5">
          <div className="flex justify-between">
            <div className="flex gap-4 items-center">
              <Input
                placeholder="Овог,нэр,регистрийн дугаараар хайх"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{ width: 300 }}
              />
              {/* <Select
              placeholder="Шагнал сонгох"
              value={selectedAward || undefined}
              onChange={(value) => setSelectedAward(value)}
              style={{ width: 200 }}
              allowClear
            >
              {awards &&
                awards.map((award) => (
                  <Option key={award.id} value={award.name}>
                    {award.name}
                  </Option>
                ))}
            </Select> */}
              <DatePicker
                placeholder="Огноо сонгох"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                style={{ width: 200 }}
                allowClear
              />
              <Button type="primary" onClick={handleSearch}>
                <FaSearch color="white" size={16} />
              </Button>
            </div>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    defaultBg: "#22c55e",
                    defaultHoverBg: "#86efac",
                    defaultHoverColor: "#fff",
                  },
                },
              }}
            >
              <Button
                // disabled={selectedRowKeys.length === 0}
                onClick={onClick}
                className="text-sm font-medium w-[120px] text-white py-5 px-5  border-none rounded-lg"
              >
                Зөвшөөрөх
              </Button>
            </ConfigProvider>
          </div>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            // dataSource={list}
            pagination={{
              showTotal: showTotal,
              current: currentPage,
              pageSize: pageSize,
              onChange: handlePageChange,
              showSizeChanger: true,
              position: ["bottomRight"],
              locale: { items_per_page: "" },
              pageSizeOptions: ["5", "10", "20", "50"],
              onShowSizeChange: (current, size) => setPageSize(size),
            }}
            dataSource={dataSource && dataSource}
          />
          <Drawer open={openDrawer} onClose={onClose}>
            <form onSubmit={onSubmit}>
              <Field className="w-full relative">
                <Label>Тайлбар</Label>
                <Textarea onChange={onChange} className="inputStyle" />
              </Field>
              <button
                type="submit"
                className="inline-flex mt-5 items-center gap-2 rounded-lg text-white  bg-[#2c73eb] hover:bg-[#2c73eb] py-1.5 px-3 text-sm/6 font-semibold  shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                Хадгалах
              </button>
            </form>
          </Drawer>
        </div>
      </div>
    </Layout>
  );
}
