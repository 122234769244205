import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Layout } from "../components/Layout";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { getOrganizations } from "../services";
import plus from "../assets/plus-sign.svg";
import moment from "moment";

export default function Register() {
  const [orgs, setOrgs] = useState([]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Байгууллагын нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Регистрийн дугаар",
      dataIndex: "register",
      key: "register",
    },
    {
      title: "Холбогдох утасны дугаар",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Цахим шуудан хаяг",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Бүртгэсэн огноо",
      key: "date",
      dataIndex: "date",
      render: (text) => moment(text).format("YYYY-MM-DD "),
    },
  ];

  function fetchOrgs() {
    getOrganizations()
      .then((response) => {
        setOrgs(response.data);
      })
      .catch((error) => {});
  }
  useEffect(() => {
    fetchOrgs();
  }, []);
  const showTotal = (total) => (
    <div className="font-semibold">Нийт - {total}</div>
  );
  return (
    <Layout>
      <Header title="Байгууллагын жагсаалт" />
      <div className="p-6 flex flex-col gap-6">
        <span className="flex justify-end">
          <Link
            to="/organizations/create"
            className="pl-4 flex items-center gap-[10px] pr-5 py-2 text-white bg-[#2C73EB] rounded-xl  text-center"
          >
            <img src={plus} alt="add" />
            <span className="text-sm font-bold">Шинэ бүртгэл</span>
          </Link>
        </span>
        <Table
          columns={columns}
          dataSource={orgs}
          pagination={{
            showTotal: showTotal,
            position: ["bottomRight"],
          }}
        />
      </div>
    </Layout>
  );
}
