import { BASE_URL } from "./index";
const token = localStorage.getItem("token");

export async function getSubOrgs() {
  try {
    const response = await fetch(`${BASE_URL}/sub-organization`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function createSubOrg({
  name,
  register,
  isNoRegister,
  email,
  phone,
  orgId,
}) {
  try {
    const response = await fetch(`${BASE_URL}/sub-organization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name,
        register,
        isNoRegister,
        email,
        phone,
        orgId,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getSingleSubOrg({ id }) {
  try {
    const response = await fetch(`${BASE_URL}/sub-organization/one/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function updateSubOrg({
  id,
  orgName,
  register,
  phone,
  email,
  isGiveAward,
}) {
  try {
    const response = await fetch(`${BASE_URL}/organization/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: orgName,
        register: register,
        phone: phone,
        email: email,
        isGiveAward: isGiveAward,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating organization:", error);
    throw error;
  }
}
export async function getSubOrgsById(id) {
  try {
    const response = await fetch(`${BASE_URL}/sub-organization/org/${id}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
